// STYLES MAIN MENU ICON
// ===================================
.wrap-menu-icon{
    display: flex;
    justify-content: space-between;
    padding: 15px 11px 21px;

    .left-menu,
    .right-menu{
        > ul{
            padding-left: 0;
            margin-bottom: 0;
    
            .item-menu-icon{
                list-style: none;
                float: left;
                margin-right: 15px;
                display: inline-flex;
    
                &:last-child{
                    margin-right: 0;
                }
    
                .item-btn{
                    background-color: transparent;
                    border: 0 none;
                    display: contents;

                    &.disable{
                        .vi{
                            color: #5B5757;
                        }
                    }

                    &:hover{
                        .vi{
                            color: $color-gray3;
                        }
                    }
                    
                    .vi{
                        color: $color-gray1;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

// CONTENT FORM GROUP
// ===========================
.content-settings{
    padding: 0 10.3px;  

    .form-group{
        margin-bottom: 0;
    
        &.side-by-side{
            display: flex;
            align-items: center;
    
            label{
                margin-right: 11px;
                line-height: 110%;
            }

            .field-icon{
                width: 24px;
                height: 24px;
                object-fit: contain;
                background: none;
                border: 0;
                display: contents;

                &:hover{
                    .vi{
                        color: $color-gray3; 
                    }
                }

                .vi{
                    color: $color-gray3;
                }
            }
        }
    
        &.content-slide{
            
            .title-content{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 11px;
    
                .badge{
                    background-color: #171717;
                    display: grid;
                    padding: 6px 0 2px;
                    margin-top: -8px;
                    border-radius: 0;
                    font-size: 14px;
                    line-height: 94.3%;
                    color: #797979 !important;
                    border: 0;
                    width: 35px;
                    height: 25px;
                    font-weight: 500;
                    
                    /* Chrome, Safari, Edge, Opera */
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    }

                    /* Firefox */
                    &[type=number] {
                        -moz-appearance: textfield;
                    }

                    &:enabled{
                        &:focus{
                            outline: none;
                            box-shadow: none;
                            border: 0;
                        }
                    }
                }
            }
        }

        &.content-input{
            display: flex;
            align-items: center;

            label{
                white-space: nowrap;
                margin-right: 12px;
            }

            .title-content{
                display: flex;
                align-items: center;
            }

            .form-control{
                background-color: $color-dark1;
                border-radius: 0;
                border: 0 none;
                padding: 6px 5px 2px;
                margin-bottom: 0;
                color: $color-gray3;

                &.price{
                    margin-top: 5px;
                    height: 35px;
                }
            }

            .dropdown-language{
                margin-left: 10px;
                margin-top: 6px;
            }
        }

        &.content-description{
            margin-bottom: 20px;

            label{
                margin-bottom: 11px;
            }

            .form-control{
                margin-bottom: 0;
                resize: none;
                font-size: 16px;
                line-height: 130.3%;
                color: $color-gray1;

                &::-webkit-scrollbar {
                    width: 4px;
                    position: absolute;
                    right: 0;
                    top: 0;
            
                    @media (max-width: 420px){
                        width: 3px; 
                    }
                }
            
                &::-webkit-scrollbar-track {
                    background: #000000;
                }
                
                &::-webkit-scrollbar-thumb {
                    background: $color-dark4; 
                    border-radius: 0;
                }
                
                &::-webkit-scrollbar-thumb:hover {
                    background: $color-dark4; 
                }
            }
        }

        &.content-color{
            margin-bottom: 30px;
            display: flex;
            align-items: center;
        }

        label{
            color: $color-gray3;
            font-size: 16px;
            margin-bottom: 0;
            font-weight: normal;
        }
    }

    .switch-artwork-settings{
        height: 25px;
        display: flex;
        margin-bottom: 15px;
    }
}

// LABEL GLOBAL EDITOR
label{
    color: $color-gray3;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 3px;
}

// OUTLINE BUTTON
.outline-bottom{
    border-bottom: solid 1px $color-dark1;
    margin-bottom: 15px;
    margin-left: -10.3px;
    margin-right: -10.3px;
}

// STYLE DISABLE ICON
.disable-icon{
    cursor: no-drop !important;

    &:hover{
        .icon-menu{
            &.stroke{
                path{
                    stroke: #5B5757 !important;  
                    fill: transparent !important;
                }  
            }
    
            path{
                fill: #5B5757 !important;  
            }
        }

        .icon-menu-maps{
            path{
                stroke: #5B5757 !important;
                fill: transparent;
            }

            circle{
                stroke: #5B5757 !important;
                fill: transparent !important;
            }
        }

        .vi{
            color: #5B5757 !important;
        }
    }

    .icon-menu{
        &.stroke{
            path{
                stroke: #5B5757 !important;  
                fill: transparent !important;
            }  
        }

        path{
            fill: #5B5757 !important;  
        }
    }

    .icon-menu-maps{
        path{
            stroke: #5B5757 !important;
            fill: transparent !important;
        }

        circle{
            stroke: #5B5757 !important;
            fill: transparent !important;
        }
    }

    .vi{
        color: #5B5757 !important;
    }
}

// back button
.btn-back-to{
    padding: 10px;

    button{
        display: flex;

        i{
            margin-right: 5px;
            margin-top: -5px;
        }
    }
}

// BUTTON ADD EDITOR
.btn-add{
    border-radius: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 10px 12px;
    background: $color-dark3;
    border: 1px solid $color-dark1;
    width: 100%;
    height: 54px;
    color: $color-gray1;
    font-size: 16px;
    line-height: 94.3%;

    &:hover{
        background-color: $color-gray2;
        color: $color-dark1;

        .icon-add{
            path{
                fill: $color-dark1;
            }
        }
    }

    &:focus{
        background-color: $color-dark4;
        color: $color-gray1;
        box-shadow: none;
        outline: none;
        border: 0;

        .vi{
            color: $color-gray1;
        }
    }

    &:disabled{
        &:hover{
            background-color: transparent;
            color: $color-gray1;

            .vi{
                color: $color-gray1;
            }
        }
    }

    i{
        margin-left: 13px;
        margin-top: -5px;
    }
}

// INFO STYLE
.wrap-info{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 5px 46px 20px 26px;
    
    span{
        font-size: 24px;
        line-height: 112.8%;
    }

    .desc-info{
        font-size: 10px;
        line-height: 112.8%;

        span{
            color: $color-gray3;
            font-size: 10px;
        }

        .file-size{
            white-space: nowrap;
            color: $color-gray1;
        }
    }
}

.wrap-btn{
    margin-top: 40px;
    margin-right: 126px;
    display: inline-flex;
    float: right;

    .btn{
        margin-right: 67px;
        padding: 0;
        width: 70px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.validation-error{
    p{
        padding-top: 10px;
        font-size: 14px;
        color: $color-danger;
    }
}

// ============================== //
// WRAP FIGURE IMAGE
// ============================== //
.item-figure{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px $color-dark1;
    padding: 1px;
    position: relative;
    border-bottom: 0;

    &.drag-list{
        cursor: move;
    }

    &:last-child{
        border-bottom: solid 1px $color-dark1;
    }

    &.disable-item{
        opacity: 0.35;
        
        &:hover{
            background-color: transparent;

            .wrap-title-figure{
                .title-figure{
                    color: $color-gray1;
                }
            }
    
            .btn-modify{
                .vi{
                    color: $color-gray1;
                }
            }

            .link-figure{
                cursor: no-drop;
            }
        }
    }

    &:hover{
        background-color: $color-gray2;
        
        .wrap-title-figure{
            .title-figure{
                color: $color-dark1;
            }
        }

        .btn-modify{
            .vi{
                color: $color-dark1; 
            }
        }
    }

    .wrap-title-figure{
        display: flex;
        align-items: center;

        .img-figure{
            width: 44px;
            height: 44px;
            object-fit: cover;
        }

        .badge{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 14px;
            height: 14px;
            object-fit: cover;
            z-index: 5;
            display: block;
            padding: 0;
            border-radius: 0;
        }

        .title-figure{
            color: $color-gray1;
            font-size: 17px;
            line-height: 130.3%;
            margin-bottom: 0;
            margin-left: 11px;
            font-family: $font-whyte;
            white-space: nowrap; 
            width: 230px; 
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .btn-modify{
        background-color: transparent;
        margin-right: 10px;
        margin-top: 9px;
        border: 0 none;
        padding: 0;

        .vi{
            color: $color-gray1;
        }
    }

    .link-figure{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 5;
    }
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
                0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
  
.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
  
.drag-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
  
.drag-placeholder {
    background: #ccc;
    border: dotted 3px #999;
    min-height: 46px;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

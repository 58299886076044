.p-calendar{
    height: 28px;

    .p-inputtext{
        background-color: $color-dark1 !important;
        color: $color-gray1;
        width: 210px !important;
        border: none 0;
        height: 28px;
        border-radius: 0;
    }

    .p-datepicker-header{
        border-bottom: solid 1px $color-dark1 !important;

        .p-datepicker-title{
            color: $color-gray1;
        }

        .p-datepicker-prev,
        .p-datepicker-next{
            color: $color-gray1 !important;
        }
    }

    .p-datepicker{
        &:not(.p-datepicker-inline){
            background-color: $color-dark1;

            .p-datepicker-header{
                background-color: $color-dark4 !important;
            }
        }

        table{
            th{
                color: $color-gray1;
            }

            td{
                &.p-datepicker-today{
                    span{
                        color: $color-gray1 !important;
                        background-color: $color-dark4 !important;
                    }
                }

                span{
                    color: $color-gray1;

                    &.p-highlight{
                        color: $color-gray1 !important;
                        background-color: $color-dark4 !important;
                    }
                }
            }
        }
    }
}
.p-inputnumber{

    .p-inputnumber-input{
        background-color: $color-dark1;
        border: solid 1px $color-dark1;
        width: 59px;
        padding: 4px 4px 0;
        text-align: center;
        color: $color-gray3;
        height: 30px;
        border-radius: 0;
        font-size: 16px;
        line-height: 101.8%;

        &:focus{
            outline: none;
            box-shadow: none;
        }
    }

    .p-inputnumber-button-group{
        margin-left: 2px;

        .p-inputnumber-button{
            &.p-button{
                background-color: $color-dark1;
                border: solid 1px $color-dark1;
                width: 9px;
                border-radius: 0;

                &:hover{
                    background-color: #565656;
                    color: $color-gray3;
                }
    
                &:focus{
                    outline: none;
                    box-shadow: none;
                }

                .p-button-icon{
                    font-size: 5px;
                    color: $color-gray3;
                }
            }
        }
    }
}
.p-tooltip{
    .p-tooltip-text{
        padding: 6px 10px 5px;
        background: $color-black;
        color: $color-gray1;
        font-size: 10px;
        line-height: 101.8%;
        min-height: 20.5px;
        border-radius: 3px;
        overflow-wrap: break-word;
    }

    &.p-tooltip-bottom{
        .p-tooltip-arrow{
            border-bottom-color: $color-black;
            top: 1px;
        }
    }

    &.p-tooltip-right{
        .p-tooltip-arrow{
            border-right-color: $color-black;
        }
    }

    &.p-tooltip-top{
        .p-tooltip-arrow{
            border-top-color: $color-black;
        }
    }

    &.p-tooltip-left{
        .p-tooltip-arrow{
            border-left-color: $color-black;
        }
    }

    &.canvas-editframe{
        max-width: 130px;

        .p-tooltip-text{
            padding: 6px 5px 5px;
            overflow-wrap: break-word;
        }
    }
}


// TESTER 
.set-grid{
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0.8;
    z-index: 999999999;
    display: none;
}

.g-recaptcha {
    transform:scale(0.85);
    -webkit-transform:scale(0.85);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
}


::-moz-selection { /* Code for Firefox */
    color: #000000;
    background: $color-gray1;
}

::selection {
    color: #000000;
    background: $color-gray1;
}

.text-white{
    color: #fff;
}

.test{
    position: fixed;
    top: 85px;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: red;
    opacity: 0.5;
    z-index: 999999;
    display: block;
}

.dropdown-menu{
    border-radius: 0;
}

.flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
}

// ANIMATION LOAD CONTENT
// ==============================
@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.cursor-disable{
    cursor: no-drop !important;
}

.animated-background {
    position: relative;

    .background-masker{
        background: #fff;
        position: absolute;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: #f6f7f8;
        background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
        background-size: 800px 104px;
        z-index: 99;
    }
}


// POST ITEM
// ==============================
.post-item{
    display: block;
    position: relative;
    font-size: 16px;
    line-height: 130.3%;
    color: $color-gray1;
    overflow: hidden;

    a{
        color: $color-gray1;
        display: block;
        margin-bottom: 5px;
        margin-top: 5px;

        &.exhibition-name{
            margin-top: 0;
            margin-bottom: 0;
            color: $color-primary;
            margin-top: 4px;
        }

        &:hover{
            color: $color-primary;
            text-decoration: none;
        }
    }

    p{
        margin-bottom: 5px;
    }

    .wrap-image{
        display: block;
        position: relative;
        overflow: hidden;

        &::before{
            content: "";
            position: absolute;
            top: 0;
            background-color: rgba(231, 231, 231, 0.2);
            left: 0;
            width: 100%;
            height: 100%;
            border: solid 1px $color-gray3;
            opacity: 0;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        a.link{
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }

        .action{
            transform: translate(0, 0);
            transition: all 0.2s ease-in-out;
            position: absolute;
            bottom: -74px;
            width: 100%;
            border-left: solid 1px $color-gray3;
            border-right: solid 1px $color-gray3;
            border-bottom: solid 1px $color-gray3;
            z-index: 2;
            display: flex;
            opacity: 1;

            .btn-action{
                background-color: rgba(41, 41, 41, 0.8);
                border: none;
                flex: 1 1 auto;
                height: 73px;
                font-size: 16px;
                color: $color-gray1;
                border-left: 1px solid #6B6B6B;
                padding: 5px 0;
                position: relative;
                min-width: 25%;
                

                &.edit {
                    display: none;

                    @media (min-width: $large-screen-up) {
                        display: block;
                    }
                }

                app-icon{
                    display: block;
                    position: absolute;
                    left: 50%;
                    bottom: 10px;
                    transform: translateX(-50%);
                    width: 16px;
                    height: 16px;

                    svg.preview{
                        position:relative;
                        top: -5px;
                        left: -5px;
                    }
                }

                .vi{
                    display: block;
                    margin-bottom: 10px;
                    font-size: 18px;
                    color: $color-gray3;
                }

                &:hover{
                    background: rgba(231, 231, 231, 0.8);
                    color: $color-dark1;

                    .vi{
                        color: $color-dark1;
                    }
                }
            }
        }

        .countdown{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(63, 63, 63, 0.8);
            text-align: center;
            z-index: 1;
    
            .content {
                margin: 0 auto;
    
                .title {
                    color: $color-white;
                    font-size: 18px;
                    margin: 0px 0px 14px;
                }
    
                .time{
                    width: 100%;
                    padding: 0 10px;
                    font-size: 9px;
                    color: $color-white;
    
                    > div {
                        color: $color-primary;
                        font-size: 40px;
                        line-height: 101.8%;
                        margin-bottom: 5px;
    
                        @media (max-width: $desktop-screen) {
                            font-size: 30px;
                        }

                        @media (max-width: $large-screen){
                            font-size: 40px;
                        }

                        @media (max-width: $small-screen){
                            font-size: 30px;
                        }
                    }
                }
    
            }
        }

        &:hover{
            &:before{
                opacity: 1;
            }

            .countdown{
                border: solid 2px $color-primary;
            }

            .action{
                transform: translate(0, -74px);
                opacity: 1;
            }
        }
    }

    .wrap-date{
        display: flex;
        text-align: right;
        
        .arrow-length{
            padding: 0 10px;
            position: relative;
            flex: 1 1 auto;

            @-moz-document url-prefix() {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
            }

            &:after{
                content: "";
                position: absolute;
                width: calc(100% - 21px);
                background-color: #979797;
                height: 1px;
                top: 50%;
                margin-top: -2px;
                left: 10px;
                border-radius: 2px;

                @-moz-document url-prefix() {
                    border-radius: 2px;
                    flex: 1;
                    position: static;
                }  
            }

            &::before{
                font-family: 'primeicons';
                content: "\e901";
                width: 0;
                top: 50%;
                margin-top: -11px;
                border-radius: 2px;
                position: absolute;
                right: 17px;
                font-size: 8px;

                @-moz-document url-prefix() {
                    margin: -2px 6px 0px -6px;
                    position: static;
                }      
            }
        }
    }

    img{
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
}

// CONTENT COMFIRM DELETE
.wrap-content-confirm{
    padding: 0;

    .desc{
        display: block;

        h3{
            color: $color-dark1;
            font-size: 22px;
            line-height: 130%;
            font-family: $font-whyte;
            margin-bottom: 20px;
        }

        p{
            color: $color-dark4;
            font-size: 16px;
            line-height: 125%;
            padding-left: 17px;
            
            > a{
                &:hover{
                    text-decoration: none;
                    color: $color-primary;
                }
            }
        }
    }

    .wrap-btn{
        margin-top: 30px;
        margin-right: 0;
        display: inline-flex;
        float: right;

        .btn{
            margin-right: 40px;
            padding: 0;
            width: 70px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 4px;
        }
    }
}

.link-admin-faq{
    color: $color-white;

    &:hover{
        color: $color-white;
    }
}


// HANDLE BUTTON GOOGLE FLICKERED
#google-btn > div > div:first-child{
    display: none;
}

* {
    @include firefox-only {
        scrollbar-color: #5E5E5E #1D1D1D;
    }

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #5E5E5E;
        border-radius: 0px;
    }

    &::-webkit-scrollbar-track {
        background: #1D1D1D;
    }
}

body {
    @include firefox-only {
        overflow: hidden;
    }
}
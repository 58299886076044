// table invoices billing
.invoices-table-list {
    min-width: 730px;
    
    .p-datatable-wrapper {
        padding: 0 5px 5px;
    }

    .p-datatable-thead {
        > tr {
            > th {
                font-size: 16px;
                color: $color-gray4;
                line-height: 20px;
                font-weight: 400;
                padding: 12px 0 8px;
                text-align: center;
            }

            .product {
                width: 64%;
            }

            .payment-status {
                width: 144px;
                min-width: 130px;
            }

            .date {
                width: 145px;
                min-width: 145px;

                @media (max-width: $large-screen) {
                    width: 134px;
                }
            }

            .amount {
                width: 120px;
                min-width: 95px;

                @media (max-width: $large-screen) {
                    width: 80px;
                }
            }

            .action {
                width: 90px;
                min-width: 90px;
            }
        }
    }

    .p-datatable-tbody {
        > tr {
            &:nth-child(odd) {
                background: $color-dark3;
            }

            &:hover {
                background: rgba(255, 218, 22, 0.05);
            }

            > td {
                font-size: 18px;
                color: $color-gray3;
                line-height: 22.5px;
                text-align: center;

                @media (max-width: $small-screen) {
                    font-size: 16px;
                    line-height: 20px;
                }

                &:first-child {
                    text-align: left;
                }

                &:last-child {
                    text-align: right;
                }

                input[type=checkbox] + label:before {
                    margin-right: 22px;
                    margin: 0 22px 0 16px;
                }

                .pending {
                    margin-left: 56px;
                    color: $color-primary;
                }

                .btn {
                    margin: 5px auto;
                    font-size: 18px;
                    border-radius: 44px;
                    outline: none;
                    display: flex;
                    align-items: center;
                    padding: 10px 11px;
                    box-shadow: none !important;

                    &-dark {
                        background-color: transparent;
                        
                        &:hover {
                            background-color: $color-dark3;
                        }
                        
                        &:focus,
                        &:active {
                            outline: none;
                            background-color: $color-black3;
                        }

                        i {
                            color: $color-primary;
                            font-size: 16px;
                        }
                    }

                    &-primary {
                        color: $color-dark1;
                        padding: 10px 15px 8px;
                        border: 0;
                        width: 115px;

                        i {
                            font-size: 18px;
                            margin-right: 5px;
                            margin-top: -2px;
                        }
                    }

                    &-danger {
                        border: 0;
                        padding: 10px 15px 8px;
                        width: 115px;
                        background-color: #F20521;

                        &:hover {
                            background-color: #6E2F37;
                        }

                        i {
                            font-size: 12px;
                            margin-right: 10px;
                            margin-bottom: 2px;
                        }
                    }
                }
            }
        }
    }
}

// table promocodes
.promocodes-table-list {
    min-width: 740px;

    .p-datatable-thead {
        > tr {
            background-color: $color-gray2;
        }

        > tr > th {
            padding: 30px 0;
            font-size: 20px;
            color: $color-dark1;
            text-align: center;

            @media (max-width: $medium-screen) {
                font-size: 16px;
            }

            &.name {
                @media (max-width: $medium-screen) {
                    width: 150px;
                }
            }

            &.action {
                text-align: end;
                padding-right: 92px;

                @media (max-width: $large-screen) {
                    width: 240px;
                }
            }
        }
    }

    .p-datatable-tbody {
        > tr {
            background-color: $color-dark3;

            &:nth-child(odd) {
                background-color: $color-dark4;
            }

            > td {
                padding: 22px 0 18px;
                font-size: 20px;
                color: $color-white;
                text-align: center;

                @media (max-width: $medium-screen) {
                    font-size: 16px;
                }

                &.name {
                    @media (max-width: $desktop-screen) {
                        padding: 22px 0 18px 25px;
                    }

                    &:first-child {
                        @media (max-width: $small-screen) {
                            text-align: left;
                        }
                    }
                }

                &.action {
                    padding: 19px 30px 28px 0;
                    text-align: end;

                    @media (max-width: $desktop-screen) {
                        padding: 19px 25px 28px 0;
                    }

                    @media (max-width: $medium-screen) {
                        padding: 22px 25px 25px 0;
                    }
                }

                .button {
                    display: flex;
                    justify-content: flex-end;

                    &__edit {
                        height: 53px;
                        padding: 12px 40px 8px 36px;
                        font-size: 18px;
                        margin-right: 30px;
                    }

                    &__delete {
                        height: 50px;
                        padding: 14px 16px 10px 15px;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
.p-dialog-mask{
    $self: &;
    overflow: auto;

    &.p-component-overlay {
        background-color: rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar{
        width: 0;
    }
    
    @media (max-width: $small-screen) {
        background-color: #9f9f9f;
        overflow-y: auto;
    }

    .p-dialog{
        box-shadow: none !important;
        bottom: 30px;

        .p-dialog-header{
            .p-dialog-header-icon{
                &:hover{
                    background: transparent;
                }
            }
        }

        .p-dialog-content{
            overflow:visible !important;
        }

        // DIALOG LOGIN FOR LOGIN COMPONENT AND SIGN UP COMPONENT
        // ==========================================================
        &.show-login{
            @media (max-width: 768px) {
                box-shadow: none;
                position: unset;
                transform: none;
                max-width: 718px;
            }


            @media (max-width: $small-screen) {
                box-shadow: none;
                position: unset;
                transform: none;
                max-width: 100%;
                max-height: 100%;
            }

            // DIALOG READER
            // ============================
            .p-dialog-header{
                background-color: #9f9f9f !important;
                padding: 78px 97px 0 !important;
                position: relative;
                margin-bottom: -1px;
        
                @media (max-width: $small-screen) {
                    padding: 65px 15px 0 !important;
                }

                .p-dialog-title{
                    color: $color-dark1;
                    font-family: $font-whyte;
                    font-size: 40px;
                    font-weight: normal !important;

                    @media (max-width: $small-screen){
                        font-size: 30px;
                    }
                }
    
                @media (max-width: 420px) {
                    font-size: 30px;
                }
        
                .p-dialog-header-icon{
                    position: absolute;
                    top: 24px;
                    right: 25px;

                    span{
                        color: $color-dark1;
                        font-size: 18px;
                    }
        
                    &:hover{
                        background-color: transparent !important;
        
                        span{
                            color: $color-gray3;
                        }
                    }
                }
            }
            
            // DIALOG CONTENT
            // =======================
            .p-dialog-content{
                background-color: #9f9f9f !important;
                padding: 20px 97px !important;
        
                @media (max-width: $small-screen) {
                    padding: 15px 15px 20px !important;
                }
        
                p{
                    color: #565656;
        
                    > a{
                        text-decoration: none;
                        color: #565656;
                        position: relative;

                        &::before{
                            content: "";
                            position: absolute;
                            bottom: 1px;
                            height: 2px;
                            width: 100%;
                            background-color: $color-dark6;

                            @media (max-width: $small-screen){
                                bottom: 0;
                                height: 1px;
                            }
                        }
        
                        &:hover{
                            color: $color-gray3;

                            &::before{
                                background-color: $color-gray3;
                            }
                        }
                    }
                }
        
                .btn-primary{
                    background-color: $color-primary;
                    color: $color-dark1;
                    height: 60px;
                    display: block;
                    margin-left: auto;

                    @media (max-width: $small-screen){
                        height: 45px;
                        font-size: 16px;
                    }
        
                    &:hover{
                        background-color: $color-gray3;
                        border-color: $color-gray3;
                    }
        
                    &:focus{
                        background-color: $color-gray1;
                        border-color: $color-gray1;
                        outline: none;
                        box-shadow: none;
                    }

                    &:disabled{
                        background-color: $color-primary;
                        border-color: $color-primary;
                    }
                }
            }
        
            // DIALOG FOOTER
            // ======================
            .p-dialog-footer{
                background-color: #9f9f9f !important;
                text-align: left !important;
                padding: 0 97px 78px !important;
                margin-top: -1px;
        
                @media (max-width: $small-screen) {
                    padding: 0 15px 78px !important;
                }
        
                .title-footer{
                    font-size: 20px;
                    color: $color-dark1;
                    font-family: $font-whyte;
                    font-size: 40px;
                    font-weight: normal !important;
                    line-height: 125%;
    
                    @media (max-width: $small-screen) {
                        font-size: 30px;
                    }
                }
        
                button{
                    background-color:  #D3D3D3;
                    width: 100% !important;
                    margin: 0 5px !important;
                    border-radius: 30px;
                    text-align: center;
                    display: block;
                    color: $color-dark1;
                    padding: 15px;
                    border-color: #D3D3D3;
        
                    &:hover{
                        background-color: $color-gray3;
                        border-color: $color-gray3;
                    }
        
                    &:focus{
                        background-color: $color-gray2 !important;
                        border-color: #6b6666;
                    }
                }
        
                .go-sign-up{
                    text-align: right;
                    margin-top: 30px;
                    color: $color-dark1;

                    @media (max-width: $small-screen){
                        margin-top: 20px;
                        font-size: 14px;
                    }
        
                    a{
                        color: $color-dark1;
                        position: relative;
                        text-decoration: none;
                        
                        &::before{
                            content: "";
                            position: absolute;
                            height: 2px;
                            width: 100%;
                            background-color: $color-dark1;
                            bottom: 1px;

                            @media (max-width: $small-screen){
                                height: 1px;
                                bottom: 0;
                            }
                        }
        
                        &:hover{
                            color: $color-primary;

                            &::before{
                                background-color: $color-primary;
                            }
                        }

                        .vi{
                            font-size: 16px;
                            font-weight: bold;

                            @media (max-width: $small-screen){
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }

        // DIALOG PRICING PLAN IN ACCOINT SETTINGS
        // =================================================
        &.pricing-plan{
            margin: 0 25px;
            top: calc(var(--mainHeightHeader) + 10px);
            max-height: none;

            @media (max-width: $large-screen) {
                width: 756px !important;
            }

            @media (max-width: $medium-screen){
                // top: calc(var(--mainHeightHeader) + 10px);
            }

            @media (max-width: $small-screen) {
                width: 100% !important;
                max-height: 100%;
                top: 0;
                margin: 0;
            }
    
            // DIALOG HEADER
            // ==========================
            .p-dialog-header{
                background-color: $color-gray2 !important;
                padding: 40px 30px;
                position: relative;

                @media (max-width: $small-screen){
                    padding: 65px 25px 30px;
                    justify-content: center;
                }

                .p-dialog-title{
                    font-size: 40px;
                    font-weight: normal;
                    font-family: $font-whyte;
                    color: $color-black;
                    line-height: 102%;

                    @media (max-width: $small-screen) {
                        font-size: 24px;
                    }
                }

                .p-dialog-header-icons{
                    position: absolute;
                    top: 24px;
                    right: 24px;
                    font-size: 16px;
                }
            }
            
            // DIALOG CONTENT
            .p-dialog-content{
                background-color: $color-gray2 !important;
                padding: 0 10px 30px;

                @media (max-width: $large-screen) {
                    padding: 0 115px 30px;
                }

                @media (max-width: $medium-screen) {
                    padding: 0 82px 30px;
                }

                @media (max-width: $small-screen) {
                    padding: 0 6px;
                }
            }

            .p-dialog-footer{
                background-color: $color-gray2 !important;

                .desc-footer{
                    text-align: center;
                    padding: 30px 0 60px;
                    color: $color-dark1;

                    @media (max-width: $small-screen) {
                        padding: 20px 30px 45px;
                        font-size: 14px;
                    }

                    > a{
                        text-decoration: none;
                        position: relative;
                        display: inline-block;
                        height: 20px;

                        @media (max-width: $small-screen){
                            height: 18px;
                        }

                        &::before{
                            content: "";
                            position: absolute;
                            height: 2px;
                            width: 100%;
                            z-index: 5;
                            bottom: -1px;
                            background-color: $color-dark1;

                            @-moz-document url-prefix() {
                                bottom: -2px;
                            }

                            @media (max-width: $large-screen){
                                height: 1px;
                            }
                        }

                        &:hover{
                            color: $color-white;

                            &::before{
                                background-color: $color-white;
                            }
                        }
                    }
                }

                .wrap-btn-close{
                    text-align: center;
                    margin-top: 60px;
                    display: none;

                    @media (max-width: $large-screen){
                        display: block;
                    }

                    @media (max-width: $small-screen) {
                        margin-top: 30px;
                    }

                    .btn{
                        background-color: #FFFFFF;

                        @media (max-width: $large-screen){
                            font-size: 20px;
                            padding: 12px 50px 8px;
                        }

                        @media (max-width: $small-screen){
                            font-size: 14px;
                            height: 36px;
                            padding: 4px 17px 0;
                        }
                    }
                }
            }
        }

        &.confirm-delete{
            @media (max-width: $small-screen){
                margin: 15px;
            }

            // DIALOG HEADER
            // ==========================
            .p-dialog-header{
                background-color: #9F9F9F !important;
                padding: 35px 30px;
                position: relative;

                @media (max-width: $small-screen){
                    padding: 30px 30px 20px;
                }

                .p-dialog-header-icons{
                    position: absolute;
                    top: 30px;
                    right: 30px;
                    color: $color-gray1;

                    @media (max-width: $small-screen){
                        top: 20px;
                        right: 20px;
                    }
                }
            }

            // DIALOG CONTENT
            .p-dialog-content{
                background-color: #9F9F9F !important;
                padding: 0 88px 46px;

                @media (max-width: $small-screen){
                    padding: 0 20px 20px;
                }

                .desc{
                    h3{
                        @media (max-width: $small-screen){
                            font-size: 18px;
                        }
                    }

                    p{
                        @media (max-width: $small-screen){
                            font-size: 14px;
                            padding: 0;
                        }
                    }
                }

                .wrap-btn{
                    @media (max-width: $small-screen){
                        margin-top: 25px;
                    }
                }

            }
        }

        &.confirm-delete-dark{

            // DIALOG HEADER
            // ==========================
            .p-dialog-header{
                background-color: $color-dark3 !important;
                padding: 40px 30px;
                position: relative;

                .p-dialog-header-icons{
                    position: absolute;
                    top: 20px;
                    right: 20px;

                    .p-dialog-header-icon{
                        color: $color-gray1;
                    }
                }
            }

            // DIALOG CONTENT
            .p-dialog-content{
                background-color: $color-dark3 !important;
                padding: 0 88px 50px;
                color: $color-gray3;

                .wrap-content-confirm{
                    .desc{                
                        h3{
                            color: $color-white;
                        }
                
                        p{
                            color: $color-gray1;

                            a{
                                &:hover{
                                    color: $color-primary !important;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.confirm-unsubcribe{

            // DIALOG HEADER
            // ==========================
            .p-dialog-header{
                background-color: #9F9F9F !important;
                padding: 40px 30px;
                position: relative;
                border-bottom: solid 1px #9f9f9f !important;

                .p-dialog-header-icons{
                    position: absolute;
                    top: 15px;
                    right: 30px;
                }
            }

            // DIALOG CONTENT
            .p-dialog-content{
                background-color: #9F9F9F !important;
                padding: 0 30px 30px;
                color: $color-dark3;
            }
        }

        &.edit-frame{
            .p-dialog-header{
                background-color: $color-dark3;
                padding: 18px 0 13px;

                .p-dialog-header-icons{
                    position: absolute;
                    top: 13px;
                    right: 13px;

                    .p-dialog-header-icon{
                        span{
                            font-size: 18px;
                            color: $color-gray1;
                        }
                    }
                }
            }

            .p-dialog-content{
                background-color: $color-dark3 !important;
                padding: 0 16px 40px;

                &::-webkit-scrollbar {
                    width: 4px;
                    position: absolute;
                    right: 0;
                    top: 0;
            
                    @media (max-width: 420px){
                        width: 3px; 
                    }
                }
            
                &::-webkit-scrollbar-track {
                    background: #000000;
                }
                
                &::-webkit-scrollbar-thumb {
                    background: $color-dark4; 
                    border-radius: 0;
                }
                
                &::-webkit-scrollbar-thumb:hover {
                    background: $color-dark4; 
                }

            }
        }

        &.edit-cover-image{
            .p-dialog-header{
                background-color: $color-dark3;
                padding: 0;

                .btn-close {
                    background: transparent;
                    border: 0;
                    color: $color-gray1;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    padding: 2px;
                    line-height: 12px;

                    &:hover {
                        color: $color-gray3;
                    }

                    i {
                        font-size: 16px;
                    }
                }
            }

            .p-dialog-content{
                background-color: $color-dark3 !important;
                padding: 42px 24px;

                &::-webkit-scrollbar {
                    width: 4px;
                    position: absolute;
                    right: 0;
                    top: 0;
            
                    @media (max-width: 420px){
                        width: 3px; 
                    }
                }
            
                &::-webkit-scrollbar-track {
                    background: #000000;
                }
                
                &::-webkit-scrollbar-thumb {
                    background: $color-dark4; 
                    border-radius: 0;
                }
                
                &::-webkit-scrollbar-thumb:hover {
                    background: $color-dark4; 
                }
            }
        }

        &.share-profile{
            // DIALOG HEADER
            // ==========================
            .p-dialog-header{
                padding: 0;

                .p-dialog-header-icons{
                    position: absolute;
                    top: 24px;
                    right: 24px;

                    .p-dialog-header-icon{
                        color: $color-dark3;
                    }
                }
            }

            // DIALOG CONTENT
            .p-dialog-content{
                background-color: #9F9F9F !important;
                padding: 45px 110px 40px;

                @media (max-width: $small-screen){
                    padding: 40px 20px 35px;   
                    margin: 15px;                 
                }

                .wrap-share{
                    .title{
                        font-size: 40px;
                        color: $color-dark3;
                        font-family: $font-whyte;
                        margin-bottom: 32px;
                        margin-left: -25px;

                        @media (max-width: $small-screen){
                            font-size: 24px;
                            margin-left: 0;
                            margin-bottom: 18px;
                        }
                    }

                    label{
                        color: $color-dark1;

                        @media (max-width: $small-screen){
                            font-size: 14px;
                        }
                    }

                    .form-group{
                        @media (max-width: $small-screen){
                            margin-bottom: 5px;
                        }
                        
                        .form-control{
                            height: 25px;
                            padding: 5px 5px 3px 5px;
                            
                            @media (max-width: $small-screen){
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }

        &.field-of-View{
            .p-dialog-header{
                background-color: $color-dark3;
            }

            .p-dialog-content{
                background-color: $color-dark3 !important;
                padding: 40px 20px 30px;
            }
        }

        // DIALOG EDIT BILLING INFO
        // ============================
        &.edit-billing-info {
            background-color: $color-gray4;
            width: 1150px;
            padding: 65px 30px 70px;

            @media (max-width: $large-screen) {
                padding: 65px 30px 70px;
                width: 726px;
                overflow-y: auto;
            }

            @media (max-width: $medium-screen) {
                padding: 65px 30px 70px;
            }

            @media (max-width: $small-screen) {
                max-height: 100%;
                height: 100vh;
                bottom: 0;
                overflow: hidden;
                padding: 0px 0 45px;
                justify-content: space-between;
            }

            .p-dialog-header{
                position: absolute;
                top: 20px;
                right: 20px;

                @media (max-width: $small-screen) {
                    top: 16px;
                    right: 16px;
                    z-index: 1;

                    &-close-icon {
                        font-size: 16px;
                    }
                }
            }

            .p-dialog-content {
                overflow-y: auto !important;
                padding: 8px 65px 0;
                @include firefox-only {
                    scrollbar-color: $scrollbar-thumb-color $scrollbar-color; // scrollbar on Firefox
                    scrollbar-width: thin; // scrollbar on Firefox
                }

                @media (max-width: $large-screen) {
                    padding: 8px 90px 0 75px;
                }

                @media (max-width: $medium-screen) {
                    padding: 8px 62px 0;
                }

                @media (max-width: $small-screen) {
                    padding: 45px 40px 0 16px;
                }

                @media (max-width: $small-screen) {
                    .scroll-overlay {
                        position: absolute;
                        width: 100%;
                        height: 50px;
                        bottom: 80px;
                        left: 0;
                        right: 0;
                        background: linear-gradient(180deg, rgba(159, 159, 159, 0) 0%, $color-gray4 86.53%);
                        
                        &.top {
                            top: 0;
                            transform: rotate(180deg);
                        }
                    }
                }

                // scrollbar on Chrome, Safari, Edge
                &::-webkit-scrollbar-track {
                    background-color: $color-gray1;
                }

                // scrollbar on Chrome, Safari, Edge
                &::-webkit-scrollbar {
                    width: 5px;
                    background-color: $color-gray1;
                }

                // scrollbar on Chrome, Safari, Edge
                &::-webkit-scrollbar-thumb {
                    background-color: $color-dark6;
                }
            }

            .edit-billing {
                &__wrapper {
                    margin-bottom: 50px;

                    @media (max-width: $small-screen) {
                        margin-bottom: 60px;
                    }
                }

                &__title {
                    color: $color-dark1;
                    font-size: 40px;
                    font-family: $font-whyte;
                    margin-bottom: 30px;

                    @media (max-width: $small-screen) {
                        font-size: 20px;
                        line-height: 24px;
                        margin-bottom: 10px;
                    }
                }

                &__row {
                    display: flex;
                    gap: 20px;
                    margin-bottom: 15px;

                    @media (max-width: $large-screen) {
                        flex-wrap: wrap;
                        gap: 15px;
                    }

                    @media (max-width: $small-screen) {
                        gap: 8px;
                        margin-bottom: 8px;
                    }
                }

                &__form {
                    display: inline-block;
                    width: 235px;

                    &.company {
                        width: 469px;
                    }

                    &.full {
                        width: 50%;
                    }

                    &.zip {
                        width: 212px;
                    }

                    @media (max-width: $large-screen) {
                        &.w50 {
                            width: 50%;
                        }

                        &,&.zip {
                            width: calc(50% - 15px);
                        }

                        &.company,
                        &.full {
                            width: 100%;
                        }
                    }

                    @media (max-width: $small-screen) {
                        &, &.w50, &.zip {
                            width: 100%;
                        }
                    }
                }

                &__label {
                    font-size: 16px;
                    color: $color-white;
                    margin: 0 0 3px;

                    @media (max-width: $small-screen) {
                        font-size: 14px;
                        margin: 0;
                    }
                }

                &__input {
                    background-color: $color-gray2;
                    height: 50px;
                    padding: 14px 15px 13px;
                    font-size: 18px;

                    &:focus,
                    &:focus-visible {
                        background-color: $color-gray3;
                    }

                    @media (max-width: $small-screen) {
                        font-size: 16px;
                        padding: 9px 10px 7px;
                        height: 36px;
                    }

                    &::placeholder {
                        color: #838383;
                        opacity: 1; /* Firefox */
                    }
                }

                &__button {
                    text-align: end;
                    padding: 30px 65px 0 0;

                    @media (max-width: $large-screen) {
                        padding: 30px 10px 0 0;
                    }
                    
                    @media (max-width: $small-screen) {
                        display: flex;
                        justify-content: space-around;
                        padding: 0;
                    }

                    .btn {
                        font-size: 16px;
                        color: $color-dark3;
                        padding: 14px 24px 12px;
                        height: 50px;
                        width: 100px;


                        @media (max-width: $small-screen) {
                            font-size: 14px;
                            height: 36px;
                            width: 80px;
                            padding: 8px 20px 6px;
                        }
                        
                        &.btn-light {
                            margin-right: 20px;
                        }
                    }
                }
            }
            
        }

        // DIALOG EDIT PAYMENT METHOD
        &.edit-payment-method {
            background-color: $color-gray4;
            width: 685px;
            padding: 80px 97px 70px;

            @media (max-width: $small-screen) {
                padding: 110px 16px 45px;
                max-height: 100%;
                height: 100vh;
                bottom: 0;

                .p-dialog-content,
                .wrap-content {
                    height: 100%;
                }
            }

            .p-dialog-header{
                position: absolute;
                top: 20px;
                right: 20px;

                &-close-icon {
                    font-size: 16px;
                }
            }

            .edit-payment {
                @media (max-width: $small-screen) {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                }

                &__wrapper {
                    margin-bottom: 50px;

                    @media (max-width: $small-screen) {
                        margin-bottom: auto;
                    }
                }

                &__title {
                    color: $color-dark1;
                    font-size: 40px;
                    font-family: $font-whyte;
                    margin-bottom: 30px;

                    @media (max-width: $small-screen) {
                        font-size: 20px;
                        line-height: 24px;
                        margin-bottom: 10px;
                    }
                }

                &__row {
                    display: flex;
                    gap: 20px;
                    margin-bottom: 15px;

                    @media (max-width: $large-screen) {
                        flex-wrap: wrap;
                        gap: 15px;
                    }

                    @media (max-width: $small-screen) {
                        gap: 8px;
                        margin-bottom: 8px;
                    }
                }

                &__form {
                    display: inline-block;
                    width: 100%;

                    &.card-number {
                        width: 280px;

                        @media (max-width: $small-screen) {
                            width: 100%;
                        }
                    }

                    &.valid,
                    &.cvc {
                        width: 85px;

                        @media (max-width: $small-screen) {
                            width: calc(50% - 5px);
                        }
                    }
                }

                &__label {
                    font-size: 16px;
                    color: $color-white;
                    margin: 0 0 3px;

                    @media (max-width: $small-screen) {
                        font-size: 14px;
                        margin: 0;
                    }
                }

                &__input {
                    background-color: $color-gray2;
                    height: 50px;
                    padding: 14px 15px 13px;
                    font-size: 18px;

                    &:focus-visible {
                        background-color: $color-gray3;
                    }

                    @media (max-width: $small-screen) {
                        font-size: 16px;
                        padding: 9px 10px 7px;
                        height: 36px;
                    }

                    &::placeholder {
                        color: #838383;
                        opacity: 1; /* Firefox */
                    }
                }

                &__button {
                    display: flex;
                    justify-content: space-between;

                    @media (max-width: $small-screen) {
                        display: flex;
                        justify-content: space-between;
                    }

                    .btn {
                        font-size: 16px;
                        color: #292929;
                        padding: 14px 24px 12px;
                        height: 50px;

                        @media (max-width: $small-screen) {
                            font-size: 14px;
                            height: 36px;
                            padding: 8px 20px 6px;

                            i {
                                display: none;
                            }
                        }
                        
                        &.btn-light {
                            margin-right: 20px;
                            background: #e7e7e7;

                            @media (max-width: $small-screen) {
                                margin-right: 16px;
                            }
                        }
                    }
                }
            }
        }

        // manage subscribtion dialog
        &.manage-subscrib {
            background-color: $color-gray4;
            padding: 80px 92px 70px;
            width: 1150px;

            @media (max-width: $large-screen) {
                width: 726px;
            }

            @media (max-width: $small-screen) {
                height: 100%;
                max-height: 100%;
                bottom: 0;
                padding: 0 15px 0;
                justify-content: center;
            }

            // header style
            .p-dialog-header-icons {
                position: absolute;
                top: 20px;
                right: 20px;
            }

            .p-dialog-title {
                .title {
                    font-size: 40px;
                    color: $color-black;
                    line-height: 40px;
                    margin-bottom: 30px;
                    font-family: $font-whyte;

                    @media (max-width: $small-screen) {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }
                }
            }

            // content style
            .wrap-content {
                .text {
                    font-size: 18px;
                    line-height: 22px;
                    color: $color-black;

                    @media (max-width: $small-screen) {
                        font-size: 14px;

                        &--right {
                            margin-left: auto;
                        }
                    }

                    &--sm {
                        font-size: 14px;
                        line-height: 19px;
                        color: $color-primary;
                    }
                }

                .wrap-progress {
                    margin-bottom: 30px;
                    text-align: end;
                }

                .wrap-button {
                    margin-bottom: 30px;

                    .button {
                        width: calc(50% - 10px);
                        background: transparent;
                        outline: none;
                        border: 1px solid $color-black;
                        border-radius: 20px;
                        height: 200px;

                        @media (max-width: $small-screen) {
                            height: 139px;
                        }

                        &:hover {
                            background: $color-gray2;
                        }

                        &:active {
                            background: $color-gray1;
                        }
                    }
                }
            }

            // footer style
            .p-dialog-footer {
                text-align: end;

                @media (max-width: $small-screen) {
                    position: absolute;
                    bottom: 45px;
                    right: 16px;
                }
            }
        }

        &.alert-dialog {
            background-color: $color-gray4;
            width: 725px;
            padding: 70px 109px 50px 75px;

            @media (max-width: $small-screen) {
                padding: 54px 20px;
                margin: auto 15px;
                bottom: 0;
            }

            .p-dialog-header {
                position: absolute;
                top: 20px;
                right: 20px;
                font-size: 16px;
            }

            .alerts {
                &__title {
                    font-size: 22px;
                    color: $color-dark1;
                    font-family: $font-whyte;
                    line-height: 28px;
                    margin-bottom: 10px;

                    @media (max-width: $small-screen) {
                        font-size: 18px;
                        line-height: 24px;
                        margin-bottom: 8px;
                    }
                }

                &__desc {
                    font-size: 16px;
                    color: #313131;
                    line-height: 20px;
                    margin-left: 6.5%;
                    margin-bottom: 30px;

                    @media (max-width: $small-screen) {
                        margin-left: 0;
                        font-size: 14px;
                    }
                }

                &__button {
                    padding-right: 15px;
                    text-align: end;

                    @media (max-width: $small-screen) {
                        display: flex;
                        justify-content: space-between;
                        padding: 0 28px;
                    }

                    .btn {
                        padding: 8px 24px 9px;
                        margin-left: 48px;

                        @media (max-width: $small-screen) {
                            height: 36px;
                            padding: 7px 24px 6px;
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        // Style for dialog add artworks
        &.add-artworks {
            &.p-dialog {
                width: 960px;
                background-color: $color-dark3;

                .p-dialog-header {
                    padding: 18px 0 28px;

                    .p-dialog-header-icons{
                        position: absolute;
                        top: 21px;
                        right: 20px;
    
                        .p-dialog-header-icon{
                            span{
                                font-size: 18px;
                                color: $color-gray1;
                            }
                        }
                    }
                }

                .p-dialog-content {
                    padding: 0 1px 60px 22px;

                    .title {
                        font-family: $font-whyte;
                        color: $color-gray1;
                        font-size: 40px;
                        margin-bottom: 45px;
                    }

                    .content-add {
                        display: flex;
                        padding-top: 24px;

                        .input-file,
                        .input-url {
                            flex: 50%;
                        }

                        .label {
                            font-size: 16px;
                            line-height: 20px;
                            color: $color-gray3;
                            margin-bottom: 10px;
                            display: block;

                            &-sub {
                                font-size: 16px;
                                line-height: 20px;
                                display: block;
                                margin: 13px 0 30px;
                            }
                        }

                        .left-content {
                            padding-left: 92px;
                            text-align: center;

                            .drop-area {
                                padding: 10px;
                                width: 348px;
                                background: $color-black3;
                                height: 300px;
                                cursor: pointer;

                                &--dash {
                                    border: 1px dashed $color-gray1;
                                    height: 100%;
                                    text-align: center;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }
                        }

                        .right-content {
                            padding: 0 74px 0 40px;

                            .wrap-input-url {
                                width: 333px;
                                height: 70px;
                                background: $color-black3;
                                padding: 10px;

                                .form-control {
                                    display: inline-block;
                                    height: 100%;
                                    padding: 15px 14px 11px;
                                    font-size: 16px;
                                    width: 242px;
                                    margin-right: 10px;
                                    color: $color-gray1;
                                    background-color: $color-dark4;

                                    &::placeholder {
                                        color: $color-gray1;
                                        opacity: 1; /* Firefox */
                                        padding-left: 12px;
                                    }
                                }

                                .btn {
                                    height: 33px;
                                    padding: 3px 22px 0;
                                    margin-bottom: 6px;

                                    &.loading {
                                        padding: 3px 16.5px 0;
                                    }

                                    .vi {
                                        font-size: 14px;
                                        font-weight: 600;
                                    }
                                }
                            }
                        }
                    }

                    .content-thumb {
                        position: relative;

                        .btn {
                            position: absolute;
                            left: 0;
                            top: 5px;
                            height: 34px;
                            padding: 3px 22px 0;

                            .vi {
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }

                        .wrap-form {
                            width: 100%;

                            .form-control {
                                font-size: 20px;
                                line-height: 26px;
                                color: $color-gray3;
                                padding: 13px 0px 5px;
                                width: 481px;
                                margin: auto;
                                margin-bottom: 10px;
                                background: transparent;
                                border-bottom: 1px solid $color-gray1;

                                &::placeholder {
                                    color: $color-gray1;
                                    opacity: 1; /* Firefox */
                                }
                            }

                            .thumb {
                                width: 481px;
                                // height: 270px;
                                margin: auto;
                                margin-bottom: 60px;

                                video, img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }

                    .content-list {
                        padding-left: 102px;
                        height: 383px;
                        overflow-y: auto;
                        margin-bottom: 68px;

                        .btn {
                            position: fixed;
                            left: 22px;
                            top: 139px;
                            height: 34px;
                            padding: 4px 22px 0;

                            .vi {
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }

                        .wrap-multi {
                            height: 100%;
                            width: 100%;
                            background: $color-dark3;
                        }

                        .wrap-item {
                            margin: 0;
                            padding: 0;
                            padding-right: 55px;
                            display: flex;
                            flex-wrap: wrap;
                            background: $color-dark3;

                            .list-item {
                                flex: 50%;
                                list-style: none;
                                margin-bottom: 20px;

                                .item {
                                    position: relative;

                                    .thumb {
                                        width: 87px;
                                        height: 60px;
                                        margin-right: 20px;
                                        object-fit: cover;
                                        object-position: center;
                                    }

                                    .badge3d {
                                        position: absolute;
                                        left: 0;
                                        bottom: 0;
                                        width: 21px;
                                    }

                                    .form-control {
                                        display: inline-block;
                                        font-size: 20px;
                                        line-height: 26px;
                                        color: $color-gray3;
                                        padding: 13px 0px 5px;
                                        width: 250px;
                                        margin: auto;
                                        margin-bottom: 10px;
                                        background: transparent;
                                        border-bottom: 1px solid $color-gray1;
        
                                        &::placeholder {
                                            color: $color-gray1;
                                            opacity: 1; /* Firefox */
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .footer {
                        padding-right: 40px;
                        
                        .btn {
                            height: 50px;
                            font-size: 16px;
                            line-height: 15px;
                            padding: 14px 31px 11px;
                        }
                    }
                }
            }
        }

        // Style for dialog media support
        &.media-support {
            &.p-dialog {
                .p-dialog-content {
                    padding: 0 22px 60px 22px;

                    .content-add {
                        padding-top: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .form-control {
                            font-size: 20px;
                            line-height: 26px;
                            color: $color-gray3;
                            padding: 13px 0px 5px;
                            width: 480px;
                            margin-bottom: 10px;
                            background: transparent;
                            border-bottom: 1px solid $color-gray1;

                            &::placeholder {
                                color: $color-gray1;
                                opacity: 1; /* Firefox */
                            }
                        }
                    
                        .preview{
                            &__video {
                                width: 481px;
                                margin-bottom: 60px;
                                height: 270px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                text-align: center;

                                * {
                                    margin-top: auto;
                                }
                                
                                .video-player {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            &__audio {
                                width: 481px;
                                margin: 0px 0 100px;

                                .audio-player {
                                    display: flex;
                                    align-items: center;
                                    background: $color-dark1;
                                    padding: 7px 15px;

                                    .button {
                                        margin-right: 16px;

                                        &__play .vi {
                                            font-weight: 600;
                                        }

                                        &__play,
                                        &__pause {
                                            background: transparent;
                                            border: 0;
                                            padding: 8px 0 0;
                                            color: $color-gray3;

                                            &:hover {
                                                color: $color-white;
                                            }

                                            &:disabled {
                                                opacity: 0.4;
                                                color: $color-gray1;
                                            }

                                            .vi {
                                                font-size: 20px;
                                            }
                                        }
                                    }

                                    .timer-bar {
                                        flex: 1;
                                    }

                                    .duration {
                                        min-width: 94px;

                                        .timer {
                                            font-size: 16px;
                                            display: inline-block;
                                            padding-top: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .footer {
                        display: flex;
                        justify-content: space-between;
                        padding-right: 18px;
                    }
                }
            }
        }
        
        &.select-overlaping-text {
            background-color: $color-dark3;
            width:600;
            padding: 50px;

            .p-dialog-header{
                position: absolute;
                top: 20px;
                right: 20px;

                .p-dialog-header-close-icon {
                    color: $color-gray1;
                }

                @media (max-width: $small-screen) {
                    top: 16px;
                    right: 16px;
                    z-index: 1;

                    &-close-icon {
                        font-size: 16px;
                    }
                }
            }

            .desc{
                h3{
                    font-size: 18px;
                }

                p{
                    font-size: 14px;
                    padding: 0;
                }
            }

            .wrap-item-text{
                margin-top: 20px;
                overflow-y: auto;
                max-height: 300px;

                /* width */
                &::-webkit-scrollbar {
                    width: 5px;
                }
                
                /* Track */
                &::-webkit-scrollbar-track {
                    background: $color-dark4;
                }
                
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: $color-dark6;
                }
                
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: $color-dark5;
                }

                > ul{
                    padding-left: 0;
                    margin-bottom: 0;
        
                    .item-text{
                        list-style: none;
        
                        &.disable-item{
                            opacity: 0.35;
                            
                            .link-text{
                                cursor: no-drop;
        
                                &:hover{
                                    background-color: transparent;
        
                                    .text{
                                        color: $color-gray1;
                                    }
        
                                    .btn-modify{
                                        .vi{
                                            color: $color-dark1;
                                        }
                                    }
                                }
                            }
                        }
        
                        .link-text{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border: solid 1px $color-dark1;
                            padding: 6px 0px 10px 10px;
        
                            &:hover  {
                                text-decoration: none;
                                background-color: $color-gray2;
        
                                .text{
                                    color: $color-dark1;
                                }
        
                                .btn-modify{
                                    .vi{
                                        color: $color-dark1;
                                    }
                                }
                            }
                            
                            &.active {
                                text-decoration: none;
                                background-color: $color-gray2;
        
                                .text{
                                    color: $color-dark1;
                                }
        
                                .btn-modify{
                                    .vi{
                                        color: $color-dark1;
                                    }
                                }
                            }
        
                            .text{
                                color: $color-gray1;
                                font-size: 17px;
                                line-height: 130.3%;
                                margin-bottom: 0;
                                font-family: $font-whyte;
                                white-space: nowrap; 
                                width: 270px; 
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
        
                            .btn-modify{
                                background-color: transparent;
                                margin-right: 8px;
                                margin-top: 5px;
                                border: 0 none;
                                padding: 0;
        
                                .vi{
                                    color: $color-gray1;
                                } 
                            }
                        }
        
                        &.empty-text {
                            font-size: 14px;
                            padding: 30px 0;
                            margin: 80px 0;
                            list-style: none;
                        }
                    }
                }
            }

            .wrap-btn-select{
                margin-top: 20px;
                display: flex;
                align-items: centers;

                .btn{
                    margin: auto;
                }
            }
        }

        &.preview-pdf {
            bottom: 0px;
            
            .p-dialog-header {
                justify-content: flex-end;
                margin-right: -40px;
                margin-bottom: -20px;

                .p-dialog-header-close-icon {
                    color: $color-gray3;
                }
            }

            .p-dialog-content {
                #pdf-canvas {
                    width: 620px;
                    height: 877px;
                }
            }
        }

        // splash screen
        &.splash-screen {
            max-height: max-content;
            bottom: 0;
            position: relative;
            background-color: $color-dark3;
            padding: 40px 0px 30px 20px;

            .p-dialog-header {
                margin-bottom: 20px;

                .text-xl {
                    color: $color-gray1;
                    font-size: 40px;
                    font-family: $font-whyte;
                    letter-spacing: -0.4px;
                }

                .wrap-btn-icons {
                    position: absolute;
                    top: 17px;
                    right: 17px;

                    ul {
                        padding: 0;
                        margin: 0;
                    }

                    .item-btn {
                        float: left;
                        list-style: none;
                    }

                    .btn-icons {
                        background-color: transparent;
                        height: 24px;
                        width: 24px;
                        outline: none;
                        border: none;
                        padding: 2px;
                        box-shadow: none;

                        &:hover {
                            .vi {
                                color: $color-gray3;
                            }
                        }

                        .vi {
                            color: $color-gray1;
                            font-size: 18px;
                        }

                        &.disable {
                            cursor: no-drop;

                            .vi {
                                color: $color-gray1;
                            }
                        }
                    }
                }
            }
        }
    }
}

.notSubsDialog {
    &.p-dialog {
    background-color: #9F9F9F;
    @media (max-width: $small-screen) {
        width: calc(100% - 30px) !important;
    }

    .p-dialog-header {
        color: #171717;
        font-family: $font-whyte;
        font-size: 22px;
        padding: 67px 0 5px 88px;

        .btn-close {
        position: absolute;
        top: 20px;
        right: 20px;
        background: transparent;
        border: 0;
        outline: none;
        font-size: 16px;
        line-height: 14px;
        padding: 4px;
        }
    }

    .p-dialog-content {
        padding: 10px 85px 25px 111px;

        p {
        color: #313131;
        font-size: 16px;
        }
    }

    .p-dialog-footer {
        padding: 0px 85px 50px 111px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .button {
        margin-left: 52px;
        outline: none;
        border: 0;
        padding: 13px 25px 8px;
        border-radius: 40px;
        font-size: 16px;
        line-height: 19.2px;
        color: #292929;
        background-color: #D3D3D3;
        
        &-cancel,
        &-subs {
            &:hover {
            background-color: #E7E7E7;
            }

            &:focus {
            background-color: #D3D3D3;
            }
        }

        &-subs {
            background-color: #FFDA16;
        }
        }
    }
    }
}
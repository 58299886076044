.p-inputswitch{
    position: relative;
    display: inline-block;
    -webkit-user-select: none;
    user-select: none;
    width: 32px;
    height: 18px;

    .p-hidden-accessible  {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .p-inputswitch-slider{
        background: $color-dark1;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        border-radius: 30px;

        &::before{
            background: $color-gray4;
            width: 16px;
            height: 16px;
            left: 1px;
            margin-top: -0.5rem;
            border-radius: 50%;
            transition-duration: 0.2s;
        }
    }

    &.p-inputswitch-checked {
        .p-inputswitch-slider{
            &::before{
                background: $color-primary;
                transform: translateX(0.88rem);
            }
        }
    }

    &.custom-tab-menu{
        margin-left: 5px;
        top: 3px;
    }

    &.input-switch-disable{
        .p-inputswitch-slider{
            &::before{
                background: #4C4B48;
            }
        }
    
        &.p-inputswitch-checked {
            .p-inputswitch-slider{
                &::before{
                    background: #4C4B48;
                }
            }
        }
    }

    &.switch-lg{
        width: 42px;
        height: 24px;

        .p-inputswitch-slider{
            &::before{
                width: 20px;
                height: 20px;
                left: 2px;
                margin-top: -0.6rem;
            }
        }

        &.p-inputswitch-checked {
            .p-inputswitch-slider{
                &::before{
                    transform: translateX(1.12rem);
                }
            }
        }
    }
}
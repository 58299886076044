@font-face {
  font-family: 'villume-icon';
  src:  url('https://assets.villume.com/DEV/assets/icons/villume-icons-admin/villume-icon.eot?t=v.1.2.4&a98dcg');
  src:  url('https://assets.villume.com/DEV/assets/icons/villume-icons-admin/villume-icon.eot?t=v.1.2.4&a98dcg#iefix') format('embedded-opentype'),
    url('https://assets.villume.com/DEV/assets/icons/villume-icons-admin/villume-icon.ttf?t=v.1.2.4&a98dcg') format('truetype'),
    url('https://assets.villume.com/DEV/assets/icons/villume-icons-admin/villume-icon.woff?t=v.1.2.4&a98dcg') format('woff'),
    url('https://assets.villume.com/DEV/assets/icons/villume-icons-admin/villume-icon.svg?t=v.1.2.4&a98dcg#villume-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.vi {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'villume-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vi-dash-2:before {
  content: "\e94f";
}
.vi-sort-by:before {
  content: "\e94e";
}
.vi-filter:before {
  content: "\e94d";
}
.vi-facebook-solid:before {
  content: "\e900";
}
.vi-Instagram-solid:before {
  content: "\e901";
}
.vi-outside-link:before {
  content: "\e902";
}
.vi-ew-resize:before {
  content: "\e903";
}
.vi-ns-resize:before {
  content: "\e904";
}
.vi-eye-closed:before {
  content: "\e905";
}
.vi-pending:before {
  content: "\e906";
}
.vi-plus-box:before {
  content: "\e907";
}
.vi-ipad:before {
  content: "\e908";
}
.vi-phone:before {
  content: "\e909";
}
.vi-monitor:before {
  content: "\e90a";
}
.vi-fullscreen:before {
  content: "\e90b";
}
.vi-silent-modify:before {
  content: "\e90c";
}
.vi-instagram:before {
  content: "\e90d";
}
.vi-start:before {
  content: "\e90e";
}
.vi-arrow-down:before {
  content: "\e90f";
}
.vi-arrow-up:before {
  content: "\e910";
}
.vi-arrow-right:before {
  content: "\e911";
}
.vi-arrow-left:before {
  content: "\e912";
}
.vi-plus:before {
  content: "\e913";
}
.vi-delete:before {
  content: "\e914";
}
.vi-redo:before {
  content: "\e915";
}
.vi-undo:before {
  content: "\e916";
}
.vi-maps:before {
  content: "\e917";
}
.vi-chevron-down-outline:before {
  content: "\e918";
}
.vi-unuploading:before {
  content: "\e919";
}
.vi-eye:before {
  content: "\e91a";
}
.vi-close-circle:before {
  content: "\e91b";
}
.vi-sign-in:before {
  content: "\e91c";
}
.vi-check-circle:before {
  content: "\e91d";
}
.vi-prev:before {
  content: "\e91e";
}
.vi-frame:before {
  content: "\e91f";
}
.vi-global:before {
  content: "\e920";
}
.vi-next:before {
  content: "\e921";
}
.vi-align-top:before {
  content: "\e922";
}
.vi-align-middle:before {
  content: "\e923";
}
.vi-align-bottom:before {
  content: "\e924";
}
.vi-locked:before {
  content: "\e925";
}
.vi-help:before {
  content: "\e926";
}
.vi-align-center:before {
  content: "\e927";
}
.vi-align-left:before {
  content: "\e928";
}
.vi-align-right:before {
  content: "\e929";
}
.vi-arrow-to-top:before {
  content: "\e92a";
}
.vi-arrow-break:before {
  content: "\e92b";
}
.vi-arrow-to-down:before {
  content: "\e92c";
}
.vi-letter-a:before {
  content: "\e92d";
}
.vi-circle:before {
  content: "\e92e";
}
.vi-help-circle:before {
  content: "\e92f";
}
.vi-home:before {
  content: "\e930";
}
.vi-facebook:before {
  content: "\e931";
}
.vi-unlocked:before {
  content: "\e932";
}
.vi-close-fullscreen:before {
  content: "\e933";
}
.vi-x:before {
  content: "\e934";
}
.vi-share:before {
  content: "\e935";
}
.vi-camera:before {
  content: "\e936";
}
.vi-bars:before {
  content: "\e937";
}
.vi-axis:before {
  content: "\e938";
}
.vi-dash:before {
  content: "\e939";
}
.vi-cube:before {
  content: "\e93a";
}
.vi-save:before {
  content: "\e93b";
}
.vi-chevron-up-outline:before {
  content: "\e93c";
}
.vi-check:before {
  content: "\e93d";
}
.vi-upload:before {
  content: "\e93e";
}
.vi-play:before {
  content: "\e93f";
}
.vi-pause:before {
  content: "\e940";
}
.vi-info:before {
  content: "\e941";
}
.vi-modify:before {
  content: "\e942";
}
.vi-fov:before {
  content: "\e943";
}
.vi-chevron-up:before {
  content: "\e944";
}
.vi-chevron-left:before {
  content: "\e945";
}
.vi-chevron-right:before {
  content: "\e946";
}
.vi-chevron-down:before {
  content: "\e947";
}
.vi-download-circle:before {
  content: "\e948";
}
.vi-play-solid:before {
  content: "\e949";
}
.vi-pause-solid:before {
  content: "\e94a";
}
.vi-avatar:before {
  content: "\e94b";
}
.vi-border-box:before {
  content: "\e94c";
}

@import '../common/mixins';

.p-scrollpanel-wrapper {
  border-radius: 0px;

  @media (min-width: $large-screen-up) {
    border-right: 14px solid $scrollbar-color;
  }
}

.p-scrollpanel-content {
  scrollbar-width: none;
  overflow-x: hidden;
}

.p-scrollpanel-bar {
  background: $scrollbar-thumb-color;
  opacity: 1;
  border-radius: 0px;
  outline: none;

  @media (max-width: $large-screen) {
    display: none;
  }

  &:focus-visible {
    outline: none;
  }

  &-y {
  width: 14px;
  }

  &-x {
    display: none;
  }
}

// start create exhibition
.select-exhibition {
  height: calc(100vh - 359px - var(--mainHeightHeader));

  @media (max-width: $desktop-screen) {
    height: calc(100vh - 368px - var(--mainHeightHeader));
  }

  @media (max-width: $large-screen) {
    height: calc(100vh - 402px - var(--mainHeightHeader));
  }

  @media (max-width: 710px) {
    height: calc(100vh - 452px - var(--mainHeightHeader));
  }

  @media (max-width: $small-screen) {
    height: calc(100vh - 286px - var(--mainHeightHeader));
  }

  @media (max-width: $small-screen) and (min-width: 375px) {
    height: calc(100vh - 256px - var(--mainHeightHeader));
  }

  &.profile {
    height: auto;
  }
}

.list-artwork {
  height: calc(100vh - 311px - var(--mainHeightHeader));

    @media (max-width: $large-screen) {
      height: calc(100vh - 346px - var(--mainHeightHeader));
    }

    @media (max-width: $small-screen) {
      height: calc(100vh - 228px - var(--mainHeightHeader));
    }

    &.profile {
      height: auto;
    }

  &.profile {
    height: auto;
  }
}

.wrap-create {
    .profile {
      .p-scrollpanel-content {
        padding-bottom: 0px;
      }
  }
}
// end create exhibition
// button
.loading-button{
    color: transparent !important;
    transition: none;
    
    &::after{
        content: '';
        display: block;
        width: 1.2em;
        height: 1.2em;
        position: absolute;
        left: calc(50% - 0.65em);
        top: calc(50% - 0.65em);
        border: 0.15em solid transparent;
        border-right-color: $color-gray1;
        border-bottom-color: $color-gray1;
        border-radius: 50%;
        animation: button-anim 0.7s linear infinite;
        opacity: 1;
    }

    @keyframes button-anim {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
}

.loading-button-primary{
    @extend .loading-button;

    &::after{
        border-right-color: #1e90ff;
        border-bottom-color: #1e90ff;
    }
}

.loading-button-light{
    @extend .loading-button;

    &::after{
        border-right-color: $color-primary;
        border-bottom-color: $color-primary;
    }
}
.btn-back{
    background-color: $color-dark4;
    color: $color-gray3;
    display: block;
    width: 100%;
    border-radius: 0;
    font-size: 16px;
    line-height: 125%;

    &:hover{
        color: $color-gray3;
    }

    .vi{
        position: relative;
        top: 2px;
    }
}

.wrap-user{
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .image-user{
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
    }

    h5{
        font-size: 16px;
        line-height: 102%;
        margin-bottom: 0;

        @media (max-width: $small-screen) {
            font-size: 14px;
        }
    }
}

.btn-circle{
    position: absolute;
    background-color: $color-dark1;
    z-index: 99;
    border-radius: 50%;
    width: 37px;
    height: 37px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;

    @media (max-width: $small-screen) {
        width: 24px;
        height: 24px;
    }

    &:hover{
        .vi{
            color: $color-primary;
        }
    }

    &:focus{
        border: 0;
        box-shadow: none;
        outline: none;
    }

    .vi{
        color: $color-white;
        font-size: 18px;

        @media (max-width: $small-screen) {
            font-size: 12px;
        }
    }
}


.main-viewer{
    &.light{

        .btn-back{
            background-color: $color-gray9;
            color: $color-dark3;
        }
    
        .wrap-user{
            h5{
                color: $color-dark3;
            }
        }

        .btn-circle{
            background-color: rgba(251, 251, 251, 0.7);
    
            &:hover{
                color: $color-black;
            }

            .vi{
                color: $color-dark6;
            }
        }

        .btn-help{
            background-color: rgba(251, 251, 251, 0.7);
            
            .icon-help{
                path{
                    fill: $color-black;

                    &.primary-color{
                        fill: $color-primary !important;
                    }
                }
            }
        }
    
        .wrap-btn-play{
            background-color: rgba(251, 251, 251, 0.7);;
    
            .item-btn{
                &:hover{
                    > i{
                        color: $color-dark6;
                    }
                }
    
                > i{
                    color: $color-black;
                }
            }
        }
    
        .button-side-content{
            background-color: rgba(251, 251, 251, 0.7);
    
            &.btn-active-content{
                background-color: rgba(251, 251, 251, 0.95);
            }

            &:hover{
                background-color: rgba(251, 251, 251, 0.95);
            }
    
            .vi{
                color: $color-dark3;
            }
        }

        .menu{
            >ul {
                .item-menu{
                    &.help-menu{
                        .link-menu{
                            color: $color-dark1;
                        }
                    }
                }
            }
        }
        
        .wrap-side-content{
            background-color: rgba(251, 251, 251, 0.95);

            .side-content{
                background: transparent;
        
                // ITEM LIST ARTWORK LIGHT STYLE
                .wrap-list-artwork{
                
                    .wrap-item-list-figure{
    
                        .list-item-figure{
                            background-color: $color-gray9 !important;
    
                            &:hover{
                                background-color: $color-gray3 !important;
                            }
                            
                            .figure{
                                .wrap-figure-name{
                                    .title-figure{
                                        color: $color-black !important;
                                    }
                    
                                    .user-figure{
                                        color: $color-dark6 !important;
                                    }
                    
                                    .year{
                                        color: $color-dark6 !important;
                                    }
                                }
                            }
            
                            .more-content{
                                color: $color-dark3 !important;
                            }
            
                            .content-description{
                                .size{
                                    color: $color-dark6 !important;
                                }
            
                                .type{
                                    color: $color-dark6 !important;
                                }
            
                                .desc{
                                    color: $color-dark3 !important;
                                    
                                }
            
                                .price{
                                    color: $color-dark6 !important;
                                }
            
                                .btn{
                                    color: $color-dark1 !important;
                                    border: solid 1px $color-primary!important;
                                    background-color: $color-white !important;
    
                                    &:hover{
                                        background-color: $color-gray3 !important;
                                        border: solid 1px $color-dark3 !important;
                                    }
                                }
                            }
                        }
                    }
                    
                }
    
                // WRAP-ABOUT EXHIBITION
                .wrap-about-exhibition{
                    
                
                    .title-exhibition{
                        color: $color-black;
                    }
                
                    .desc{
                        color: $color-dark3;
                        
                    }
                
                    .date{
                        color: $color-dark6;
                    }
                
                    .wrap-share{
                        >ul{
                            .item-share{
                                .link-share{
                                    background-color: $color-dark6;
                                    border: solid 1px $color-dark6;
                
                                    &:hover{
                                        border: solid 1px $color-primary;
                                    }
                
                                    .vi{
                                        color: #f3f3f3;
                                    }
                
                                    .icon-share-social{
                                        path{
                                            stroke: #f3f3f3;
                                        }
                                    }
                                }
                            }
                        }
                    }
                
                    .menu-exhibition{
                        >ul{
                            .item-menu{
                                .link-menu{
                                    color: $color-dark3;
                                    background-color: $color-gray7;
                
                                    &:hover{
                                        background-color: $color-gray3;
                                        color: $color-black;
                
                                        .icon-share{
                                            path{
                                                stroke: $color-dark3;
                                            }
                                        }
                                    }
    
                                    .icon-share{
                                        path{
                                            stroke: $color-dark3;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                // EXHIBITION
                .wrap-exhibiton{
                    .wrap-current-exhibitions,
                    .wrap-past-exhibitions{
                        .title{
                            color: $color-dark6;
                        }
                
                        .item-exhibition{
                            background-color: $color-gray7 !important;
                
                            .wrap-exhibition-name{
                                .title-exhibition{
                                    color: $color-black !important;
                                }
                
                                .date{
                                    color: $color-dark6 !important; 
                                }
                            }
                        }
                    }
                }
            }
        }
    
        .wrap-detail-figure{
            background-color: rgba(251, 251, 251, 0.95);
            
    
            .title-figure{
                color: $color-black;
            }
    
            .size{
                color: $color-dark6;
            }
    
            .type{
                color: $color-dark6;
            }
    
            .user{
                color: $color-dark6;
            }

            .show-desc{
                color: $color-dark6;
    
                i{
                    color: $color-primary;
                }
            }
            
            .wrap-detail-desc{
                .wrap-desc{
                    .desc{
                        color: $color-dark3;
                        
                        &.on-shadow{
                            &::before{
                                background-image: linear-gradient(360deg, #EDEDED 6.54%, rgba(196, 196, 196, 0) 136.47%);
                            }
                        }
                    }
                }
            }
    
            .price{
                color: $color-dark6;
            }
    
            .btn{
                color: $color-dark1 !important;
                border: solid 1px $color-primary!important;
                background-color: $color-white !important;

                &:hover{
                    background-color: $color-gray3 !important;
                    border: solid 1px $color-dark3 !important;
                }
            }
        }

        .wrap-detail-figure-hover{
            background: rgba(251, 251, 251, 0.7);
    
            .figure-name{
                color: $color-black;
            }
    
            .username{
                color: $color-dark3;
            }
    
            .price{
                color: $color-dark3;
            }
        }
    }
}
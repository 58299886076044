@layer primeng {
    .p-toast {
        position: fixed;
        width: 25rem;
    }

    .p-toast-message {
        overflow: hidden;
    }

    .p-toast-message-content {
        display: flex;
        align-items: flex-start;
    }

    .p-toast-message-text {
        flex: 1 1 auto;
    }

    .p-toast-top-right {
        top: 20px;
        right: 20px;
    }

    .p-toast-top-left {
        top: 20px;
        left: 20px;
    }

    .p-toast-bottom-left {
        bottom: 20px;
        left: 20px;
    }

    .p-toast-bottom-right {
        bottom: 20px;
        right: 20px;
    }

    .p-toast-top-center {
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
    }

    .p-toast-bottom-center {
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
    }

    .p-toast-center {
        left: 50%;
        top: 50%;
        min-width: 20vw;
        transform: translate(-50%, -50%);
    }

    .p-toast-icon-close {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        flex: none;
    }

    .p-toast-icon-close.p-link {
        cursor: pointer;
    }
}

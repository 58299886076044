.introjs {
    font-family: $font-suisse;

    &-tooltip {
        background-color: $color-black;
        
        &-header {
            padding: 20px 20px 0;
            position: unset;
        }

        &-title {
            color: $color-gray1;
            font-size: 14px;
            font-family: $font-suisse;
            font-weight: 400;
        }
    }

    &-tooltiptext {
        color: $color-gray3;
        font-size: 16px;
        font-family: $font-suisse;
        padding: 0 20px 20px;
    }

    &-tooltipbuttons {
        border: none;
        padding: 0 20px 20px;
    }

    &-button {
        transition: none;
        padding: 10px 18px;
        border-radius: 32px;
        font-size: 16px;
        line-height: 20px;
        border: none;
        text-shadow: none;

        &:focus {
            box-shadow: none;
            border: none;
            background-color: $color-primary;
        }

        &:hover {
            background-color: $color-primary;
            color: $color-dark1;
        }
    }

    &-prevbutton {
        background: $color-dark6;
        color: $color-gray3;
    }

    &-nextbutton {
        background: $color-primary;
        color: $color-dark1;

        &:active {
            background-color: $color-gray4;
        }

        i {
            font-size: 14px;
            margin-left: 5px;
            font-weight: 600;
            vertical-align: middle;
        }
    }

    &-skipbutton {
        top: unset;
        right: unset;
        bottom: 20px;
        left: 18px;
        width: 94px;
        height: 41px;
        z-index: 2;
        opacity: 0;
    }

    &-helperLayer {
        box-shadow: none !important;
    }

    &-arrow {
        border-width: 13px;  
        z-index: -1;

        &.top,
        &.top-middle {
            top: -25px;
            border-bottom-color: $color-black;
        }

        &.bottom,
        &.bottom-middle {
            bottom: -25px;
            border-top-color: $color-black;
        }

        &.left,
        &.left-middle {
            left: -25px;
            border-right-color: $color-black;
        }

        &.right,
        &.right-middle {
            right: -25px;
            border-left-color: $color-black;
        }
    }
}
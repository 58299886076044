@layer primeng {
    .p-colorpicker {
        display: inline-block;
    }

    .p-colorpicker-dragging {
        cursor: pointer;
    }

    .p-colorpicker-overlay {
        position: relative;
    }

    .p-colorpicker-panel {
        position: relative;
        width: 193px;
        height: 166px;
    }

    .p-colorpicker-overlay-panel {
        position: absolute;
        top: 0;
        left: 0;
    }

    .p-colorpicker-preview {
        cursor: pointer;
    }

    .p-colorpicker-panel .p-colorpicker-content {
        position: relative;
    }

    .p-colorpicker-panel .p-colorpicker-color-selector {
        width: 150px;
        height: 150px;
        top: 8px;
        left: 8px;
        position: absolute;
    }

    .p-colorpicker-panel .p-colorpicker-color {
        width: 150px;
        height: 150px;
    }

    .p-colorpicker-panel .p-colorpicker-color-handle {
        position: absolute;
        top: 0px;
        left: 150px;
        border-radius: 100%;
        width: 10px;
        height: 10px;
        border-width: 1px;
        border-style: solid;
        margin: -5px 0 0 -5px;
        cursor: pointer;
        opacity: 0.85;
    }

    .p-colorpicker-panel .p-colorpicker-hue {
        width: 17px;
        height: 150px;
        top: 8px;
        left: 167px;
        position: absolute;
        opacity: 0.85;
    }

    .p-colorpicker-panel .p-colorpicker-hue-handle {
        position: absolute;
        top: 150px;
        left: 0px;
        width: 21px;
        margin-left: -2px;
        margin-top: -5px;
        height: 10px;
        border-width: 2px;
        border-style: solid;
        opacity: 0.85;
        cursor: pointer;
    }
}

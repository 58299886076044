.p-toast{
    @media (max-width: $small-screen){
        left: 15px;
        right: 15px;
        width: calc(100% - 30px) !important;
    }
    
    .p-toast-message{
        padding: 30px;
        border: 0 !important;
        margin-top: 20px;

        @media (max-width: $small-screen){
            padding: 15px;
        }
    
        .p-toast-message-content{
            padding: 0 !important;
    
            .p-toast-message-icon{
                display: none;
            }
    
            .p-toast-message-text{
    
                .p-toast-summary{
                    color: $color-dark1;
                    font-size: 24.5385px;
                    line-height: 102%; 
                    font-family: 'whyte_inktrapregular';
                    margin-bottom: 10px;
                }
    
                .p-toast-detail{
                    color: $color-dark1; 
                    font-size: 16px;
                    line-height: 125%;
                    margin-left: 13px;
                }
            }
    
            .p-toast-icon-close{
                position: absolute;
                right: 8px;
                top: 13px;
    
                .p-toast-icon-close-icon{
                    color: $color-dark1; 
                    font-size: 16px;
                }

                &:hover{
                    background: transparent;
                }
            }
        }

        &.p-toast-message-success{
            background-color: rgba(86, 237, 72, 0.9) !important;
        }
    
        &.p-toast-message-error{
            background-color: rgba(255, 62, 62, 0.9) !important;
        }
    
        &.p-toast-message-warn{
            background-color: rgba(255, 218, 22, 0.9) !important;
        }
    
        &.p-toast-message-info{
            background-color: rgba(41, 174, 249, 0.9) !important;
        }
    }
}
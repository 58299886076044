// INPUT STYLE GLOBAL
// ==============================
.form-control{
    color: $color-dark1;
    background-color: $color-gray4;
    padding: 13px 12px 12px;
    border: 0;
    border-radius: 0;
    background-image: none !important;
    height: 40px;

    /* width */
    &::-webkit-scrollbar {
        width: 5px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: $color-gray2;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $color-dark6;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $color-dark5;
    }

    &::placeholder{
        color: $color-dark1;
    }

    &::-ms-reveal {
        display: none;
    }

    &:focus{
        background-color: $color-gray4;
        box-shadow: none;
        outline: none;
        color: $color-dark1;
    }

    &.radius{
        border-radius: 30px;
    }

    &.bg-transparent{
        color: $color-gray3;
        border: solid 1px $color-gray3;
        background: transparent;

        &.border-gray{
            color: $color-gray1;
            border: solid 1px $color-gray1;
        }

        &::placeholder{
            color: $color-gray1;
        }

        &:focus{
            background-color: $color-dark3;
        }    
    }

    &.bg-dark{
        color: $color-gray3;
        background-color: $color-dark1 !important;

        &::placeholder{
            color: $color-gray1;
        }

        &:focus{
            background-color: $color-dark1;
        } 
        
        &:disabled{
            color: #979797;

            &::placeholder{
                color: #979797;
            }
        }
    }

    &.bg-gray{
        background-color: $color-gray2;
        color: $color-white;
        
        &::placeholder{
            color: $color-gray3;
        }
    }

    &.lg{
        height: 59px;
        font-size: 22px;
        padding: 5px 20px 0;
    }

    &.md{
        height: 30px;
        padding: 8px 5px 3px 5px;
    }

    &.sm{
        padding: 8px 20px;
    }

    &.xs{
        padding: 4px 5px 0;
        font-size: 14px;
        height: 20px;
    }

    &.is-invalid{
        border: solid 1px red;
        color: red;
    }

    &.input-search{
        height: 50px;
        padding: 2px 30px 0;
        font-size: 22px;
    }
}

// SELECT RADIO STYLE COSTUM
// ==============================
input[type=radio]{
    visibility: hidden;
    position: absolute;
}

input[type=radio] + label:before{
    height:14px;
    width:14px;
    margin-right: 5px;
    content: "";
    display:inline-block;
    vertical-align: baseline;
    background: #777;
}

input[type=radio]:checked + label:before{
    background: $color-primary;
}

// SELECT CHECKBOX STYLE COSTUM
// ==============================
input[type=checkbox]{
    visibility: hidden;
    position: absolute;
}

input[type=checkbox] + label:before{
    height:18px;
    width:18px;
    margin-right: 5px;
    content: "";
    display:inline-block;
    vertical-align: baseline;
    background: #777;
    position: relative;
    top: 2.5px;
}

input[type=checkbox]:checked + label:before{
    background: $color-primary;
}

// =================== //
// switch theme
// =================== //
.wrap-theme{
    width: 98px;
    height: 34px;
    background: #fbfbfb;
    margin-right: 30px;
    margin-top: -1px;

    &.wrap-light-mode{
        background: $color-dark3;
        border: solid 1px $color-gray3;
    }

    .show-theme {
        width: 48%;
        height: 91%;
        background: $color-dark3;
        position: relative;
        top: 5%;
        transition: 0.2s ease;

        &.tg-left{
            left: 2%;
        }

        &.light-mode{
            left: 50%;
            background-color: #fbfbfb;
        }

        input{
            position: relative;
            width: 100%;
            height: 100%;
            opacity: 0;
            margin: 0;
            z-index: 1;
        }
        
        a{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 5;
            height: 100%;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            i{
                color: $color-gray3;

                &.icon-light-mode{
                    color: $color-dark3;
                }
            }

            &:hover{
                text-decoration: none;
            }
        }
    }

    &.sm{
        width: 58px;
        height: 16px;

        .show-theme{
            top: 0.55px;
            
            a{
                i{
                    font-size: 12px;
                }
            }
        }
    }
}
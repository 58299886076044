.row{
    margin: 0 -10px !important;

    @media (max-width: 1440px) {
        margin: 0 !important;
    }

    > .col{
        padding: 0 10px;

        @media (max-width: $desktop-screen) {
            padding: 0;
        }
    }
}

/* COL 1 */
.col16-1 {
    -ms-flex: 0 0 6.25%;
    flex: 0 0 6.25%;
    max-width: 6.25%;
}
  
  /* COL 2 */
.col16-2 {
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
}
  
  /* COL 3 */
.col16-3 {
    -ms-flex: 0 0 18.7500000001%;
    flex: 0 0 18.7500000001%;
    max-width: 18.7500000001%;
}
  
/* COL 4 */
.col16-4 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

/* COL 5 */
.col16-5 {
    -ms-flex: 0 0 31.25%;
    flex: 0 0 31.25%;
    max-width: 31.25%;
}

/* COL 6 */
.col16-6 {
    -ms-flex: 0 0 37.4999999995%;
    flex: 0 0 37.4999999995%;
    max-width: 37.4999999995%;
}

/* COL 7 */
.col16-7 {
    -ms-flex: 0 0 43.7500000003%;
    flex: 0 0 43.7500000003%;
    max-width: 43.7500000003%;
}

/* COL 8 */
.col16-8 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

/* COL 9 */
.col16-9 {
    -ms-flex: 0 0 56.2499999993%;
    flex: 0 0 56.2499999993%;
    max-width: 56.2499999993%;
}

/* COL 10 */
.col16-10 {
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%;
}

/* COL 11 */
.col16-11 {
    -ms-flex: 0 0 68.7500000021%;
    flex: 0 0 68.7500000021%;
    max-width: 68.7500000021%;
}

/* COL 12 */
.col16-12 {
    -ms-flex: 0 0 75.0000000019%;
    flex: 0 0 75.0000000019%;
    max-width: 75.0000000019%;
}

/* COL 13 */
.col16-13 {
    -ms-flex: 0 0 81.249999998%;
    flex: 0 0 81.249999998%;
    max-width: 81.249999998%;
}

/* COL 14 */
.col16-14 {
    -ms-flex: 0 0 87.4999999967%;
    flex: 0 0 87.4999999967%;
    max-width: 87.4999999967%;
}

/* COL 15 */
.col16-15 {
    -ms-flex: 0 0 93.7499999971%;
    flex: 0 0 93.7499999971%;
    max-width: 93.7499999971%;
}

/* COL 16 */
.col16-16 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

// offset 1
.offset16-1 {
  	margin-left: 6.25% !important;
}

// offset 2
.offset16-2 {
  	margin-left: 12.5% !important;
}

// offset 3
.offset16-3 {
  	margin-left: 18.7500000001% !important;
}

// offset 4
.offset16-4 {
  	margin-left: 25% !important;
}

// offset 5
.offset16-5 {
  	margin-left: 31.25% !important;
}

// offset 6
.offset16-6 {
  	margin-left: 37.4999999995% !important;
}

// offset 7
.offset16-7 {
  	margin-left: 43.7500000003% !important;
}

// offset 8
.offset16-8 {
  	margin-left: 50% !important;
}

// offset 9
.offset16-9 {
  	margin-left: 56.2499999993% !important;
}

// offset 10
.offset16-10 {
  	margin-left: 62.5% !important;
}

// offset 11
.offset16-11 {
  	margin-left: 68.7500000021% !important;
}

// offset 12
.offset16-12 {
	margin-left: 75.0000000019% !important;
}

// offset 13
.offset16-13 {
	margin-left: 81.249999998% !important;
}

// offset 14
.offset16-14 {
	margin-left: 87.4999999967% !important;
}

// offset 15
.offset16-15 {
	margin-left: 93.7499999971% !important;
}

@media (max-width: $desktop-screen) {
    .offset-desktop-0 {
        margin-left: 0 !important;
    }

    .offset-desktop-1 {
        margin-left: 6.25% !important;
    }
}

@media (max-width: $large-screen){
    .col-iped-1{
        -ms-flex: 0 0 12.5%;
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }

    .col-iped-2{
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-iped-3{
        -ms-flex: 0 0 37.5%;
        flex: 0 0 37.5%;
        max-width: 37.5%;
    }

    .col-iped-4{
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-iped-5{
        -ms-flex: 0 0 62.5%;
        flex: 0 0 62.5%;
        max-width: 62.5%;
    }

    .col-iped-6{
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-iped-7{
        -ms-flex: 0 0 87.5%;
        flex: 0 0 87.5%;
        max-width: 87.5%;
    }

    .col-iped-8{
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .offset-iped-0{
        margin-left: 0 !important;
    }

    .offset-iped-1{
        margin-left: 12.5% !important; 
    }

    .offset-iped-2{
        margin-left: 25% !important; 
    }

    .offset-iped-3{
        margin-left: 37.5% !important; 
    }

    .offset-iped-4{
        margin-left: 50% !important; 
    }

    .offset-iped-5{
        margin-left: 62.5% !important; 
    }

    .offset-iped-6{
        margin-left: 75% !important; 
    }

    .offset-iped-7{
        margin-left: 87.5% !important; 
    }

    .offset-iped-8{
        margin-left: 100% !important; 
    }
}

@media (max-width: $small-screen){
    .col-mobile-1{
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-mobile-2{
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-mobile-3{
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-mobile-4{
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .offset-mobile-0{
        margin-left: 0 !important;
    }

    .offset-mobile-1{
        margin-left: 25% !important;
    }

    .offset-mobile-2{
        margin-left: 50% !important;
    }

    .offset-mobile-3{
        margin-left: 75% !important;
    }

    .offset-mobile-4{
        margin-left: 100% !important;
    }
}
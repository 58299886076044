.p-slider{
    background: $color-primary !important;
    border-radius: 0 !important;

    &.disable-slider{
        background: $color-gray1 !important;

        .p-slider-range{
            background: $color-gray1 !important;
        }

        .p-slider-handle{
            width: 14px !important;
            height: 14px !important;
            background: $color-gray1 !important;
        }
    }

    &.p-slider-horizontal{
        height: 1px !important;

        .p-slider-handle{
            margin-top: -0.4515rem !important;
            margin-left: -0.4515rem !important;
        }
    }

    .p-slider-range{
        background: $color-primary !important;
    }

    .p-slider-handle{
        width: 15px !important;
        height: 15px !important;
        background: $color-primary !important;
        border: solid 2px $color-dark3 !important;
        border-radius: 10px;
    }

    &.splash {
        background: $color-gray2 !important;

        .p-slider-range {
            background: $color-gray2 !important;
        }

        .p-slider-handle {
            background: $color-gray2 !important;
            height: 17px !important;
            width: 17px !important;
            border: 2px solid $color-dark3 !important;

            &:hover {
                background: $color-gray3 !important;
            }
        }
    }
}
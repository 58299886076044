.btn{
    font-size: 22px;
    height: 60px;
    border-radius: 30px; 
    white-space: nowrap;
    padding: 12px 27.5px 8px;
    position: relative;

    &:focus{
        outline: none;
        box-shadow: none;
        border: 0;

        @if $enable-shadows {
            box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($color-gray1, .5);
        } @else {
            box-shadow: 0 0 0 $btn-focus-width rgba($color-gray1, .5);
        }
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active{
        &:focus {
            // Avoid using mixin so we can pass custom focus shadow properly
            @if $enable-shadows {
                box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color-gray1, .5);
            } @else {
                box-shadow: 0 0 0 $btn-focus-width rgba($color-gray1, .5);
            }
        }
    }

    &:disabled {
        opacity: 0.35;
        cursor: no-drop !important;
    }

    // ========================== //
    // CUSTOM COLORS
    // ========================== //
    &.btn-primary{
        color:  $color-dark1;
        box-shadow: none !important;
        border: 0;
        
        @include button-variant(
            $color-primary, 
            $color-primary, 
            $color-white, 
            $color-white, 
            $color-gray2,
            $color-gray2
        );
    }

    &.btn-light2{
        color:  $color-dark1;

        @include button-variant(
            $color-gray4, 
            $color-gray4, 
            $color-gray3, 
            $color-gray3, 
            $color-gray1,
            $color-gray1
        );

        &:not(:disabled):not(.disabled):focus {
            box-shadow: none;
            border: 0;
        }
    }

    &.btn-outline-primary{
        @include button-outline-variant(
            $color-primary, 
            $color-primary, 
            $color-dark4, 
            $color-primary, 
        );
        color: $color-primary;

        &:hover,
        &:focus, 
        &:active,
        &:not(:disabled):not(.disabled):active {
            color: $color-primary;
        }
    }

    &.btn-outline-default{
        color: $color-gray3;

        @include button-outline-variant(
            $color-gray3, 
            $color-gray1, 
            $color-dark4, 
            $color-gray1, 
        );

        &:focus{
            box-shadow: 0 0 0 $btn-focus-width rgba($color-primary, .1);
        }
    }

    &.btn-outline-default2{
        color: $color-gray1;

        @include button-outline-variant(
            $color-gray1, 
            $color-gray3, 
            transparent, 
            $color-gray3, 
        );

        &:focus{
            // box-shadow: 0 0 0 $btn-focus-width rgba($color-primary, .1);
            box-shadow: none;
            border: 1px solid $color-gray2;
        }
    }

    &.btn-outline-light {
        @include button-outline-variant(
            $color-white, 
            $color-primary, 
            $color-dark2, 
            $color-primary, 
        );
        @include hover() {
            color: $color-primary;
            background-color: transparent;
            border-color: $color-primary;
        }
        color: $color-white;

        &:hover,
        &:focus, 
        &:active,
        &:not(:disabled):not(.disabled):active {
            color: $color-primary;
            border: 1px solid $color-primary;
            box-shadow: none;
        }
    }


    &.btn-light{
        color: $color-dark1;
        border: 0;
        box-shadow: none !important;
        
        @include button-variant(
            $color-gray3, 
            $color-gray3, 
            $color-white, 
            $color-white, 
            $color-gray2,
            $color-gray2
        );
    }

    &.btn-basic{
        background-color: $color-gray3;

        &:hover{
            background-color: $color-primary;
        }

        &:focus{
            outline: none;
            box-shadow: none;
            background-color: $color-gray1;
            border: solid 1px $color-gray1;
        }
    }

    &.btn-dark {
        @include button-variant(
            transparent, 
            transparent, 
            $color-dark3, 
            $color-dark3, 
            $color-black3,
            $color-black3
        );
    }

    &.btn-dark2 {
        @include button-variant(
            $color-dark3, 
            $color-primary, 
            $color-dark1, 
            $color-primary, 
            $color-black,
            $color-primary
        );
        color: $color-primary;

        &:hover,
        &:focus, 
        &:active,
        &:not(:disabled):not(.disabled):active:focus {
            color: $color-primary;
            border: 1px solid $color-primary;
            box-shadow: none;
        }

        &:focus {
            background-color: $color-black;
            box-shadow: none;
        }
    }

    // ========================== //
    // CUSTOM SIZE
    // ========================== //
    &.btn-sm{
        height: 40px;
        font-size: 16px;
        padding: 3px 15px 0;
        align-items: center;
        justify-content: center;

        .pi{
            position: relative;
            top: 1px;
            font-size: 12px;
        }
    }

    &.btn-lg{
        height: 94px;
        padding: 9px 50px 5px;
        border-radius: 100px;
    }

    &.btn-xs{
        height: 29px;
        padding: 3px 17px 0;
        border-radius: 40px;
        font-size: 16px;
    }
}
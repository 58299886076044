// Font
//============================
@import 'assets/scss/common/fonts';

@import 'assets/scss/common/mixins';
@import 'assets/scss/common/variables';

// import minimum partials used for this page
// ===========================
$custom-file-text: (); // --> Warning: 1 rules skipped due to selector errors: .custom-file-input:lang(en) ~ .custom-file-label -> unmatched pseudo-class :lang
@import "node_modules/bootstrap-scss/bootstrap.scss";

// Costum style Bootstrap
// ===========================
@import 'assets/scss/bootstrap/button';
@import 'assets/scss/bootstrap/grid';
@import 'assets/scss/bootstrap/input';

// import primeng
// ===========================
@import 'primeng/resources/components/toast/toast.css';
@import 'primeng/resources/components/tooltip/tooltip.css';
@import 'primeng/resources/components/colorpicker/colorpicker.css';
@import 'primeng/resources/primeng.min.css';
@import 'primeicons/primeicons.css';

// import owl carousel
@import 'https://assets.villume.com/DEV/assets/plugins/owl-carousel/owl.carousel.min.css?t=v.1.2.4';

// Custom style IntroJS
@import 'assets/scss/introJs/introJsTooltip';

// Costum style primeNg
@import 'assets/scss/primeNg/dropdown';
@import 'assets/scss/primeNg/dialog';
@import 'assets/scss/primeNg/input-switch';
@import 'assets/scss/primeNg/calender';
@import 'assets/scss/primeNg/slider';
@import 'assets/scss/primeNg/text-input';
@import 'assets/scss/primeNg/input-number';
@import 'assets/scss/primeNg/overlay-panel';
@import 'assets/scss/primeNg/toast';
@import 'assets/scss/primeNg/color-picker';
@import 'assets/scss/primeNg/tooltip';
@import 'assets/scss/primeNg/progress-spiner';
@import 'assets/scss/primeNg/table';
@import 'assets/scss/primeNg/progress-bar';
@import 'assets/scss/primeNg/scrollpanel';


// Asset General
// ===============================
@import 'assets/scss/common/helper';
@import 'assets/scss/common/button.loading';
@import 'assets/scss/common/global';
@import 'assets/scss/common/16grid';
@import 'assets/scss/common/typography';
@import 'assets/scss/common/editor';
@import 'assets/scss/common/viewer';
@import 'assets/scss/common/icons';

.p-dropdown{
    border: solid 1px $color-white;
    outline: 0;
    width: 100%;
    border-radius: 0;
    background-color: $color-dark3;
    border: solid 1px $color-gray3;
    height: 30px;
    font-size: 20px;

    &:focus{
        outline: none;
        box-shadow: none;
        border: none;
    }

    .p-dropdown-label{
        color: $color-gray3;
        padding: 0 8px;
        font-family: $font-suisse;
        position: relative;
        top: 2px;
        display: flex;
        align-items: center;
    }

    .p-dropdown-trigger{
        padding-right: 8px;
        color: $color-gray3;
        font-size: 16px;
    }

    .p-dropdown-panel{
        border: solid 1px $color-white;
        left: 0;
        top: -1px;
        width: calc(100% + 2px);
        margin-left: -1px;
        // margin-top: -35px;
        background-color: $color-dark3;

        .p-dropdown-items-wrapper{
            ul.p-dropdown-items {
                margin: 0;
                padding: 0;
            }

            .p-dropdown-item{
                color: $color-gray3;
    
                .item-dropdown{
                    position: relative;
                    display: block;
                    width: 100%;
                    padding: 5px 8.5px 3px;
    
                    &:hover,
                    &.p-highlight{
                        background: $color-dark1;
                        color: $color-white;
                    }
    
                    > div{
                        white-space: nowrap; 
                        flex: 1 1 auto!important;
                        padding-right: 5px;
                    }
    
                    i{
                        position: absolute;
                        right: 0;
                        padding: 0 7px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 16px;
                    }
                }
            }
        }
    }

    // CUSTOM COLOR
    // ============================= //
    &.bg-dark{
        background-color: $color-dark1 !important;
        border-color: $color-dark1;
        color: $color-gray1;

        .p-dropdown-trigger,
        .p-dropdown-label{
            color: $color-gray1;
        }

        .p-dropdown-panel{
            background-color: $color-dark1;
            border: solid 1px $color-dark1;

            .p-dropdown-item{
                .item-dropdown{
                    color: $color-gray1;

                    &:hover,
                    &.p-highlight{
                        background: $color-dark4;
                        color: $color-gray3;
                    }
                }
            }
        }
    }

    // CUSTOM COLOR
    // ============================= //
    &.bg-gray{
        background-color: $color-gray5;
        border-color: $color-white;
        color: $color-white;

        .p-dropdown-panel{
            background-color: $color-gray5;

            .p-dropdown-item{
                .item-dropdown{
                    color: $color-white;

                    &:hover,
                    &.p-highlight{
                        background: $color-gray2;
                        color: $color-white;
                    }
                }
            }
        }
    }


    &.dropdown-filter{
        .p-dropdown-label{
            color: $color-dark1;
            font-size: 16px;
            line-height: 101.8%;
        }

        .p-dropdown-trigger{
            display: none;
        }

        .p-dropdown-panel{
            background-color: $color-gray4;
            border: 0;
            box-shadow: 0px -4px 4px rgba(41, 41, 41, 0.11);
            margin-top: 0 !important;

            .p-dropdown-header{
                .p-dropdown-filter-container{

                    .p-dropdown-filter{
                        border: 0;
                        font-size: 16px;
                        height: 34px;
                        line-height: 101.8%;
                        background-image: none !important;
                        outline: none;
                        padding: 8px 10px 3px;
                    }

                    .p-dropdown-filter-icon{
                        margin-top: 0;
                        position: absolute;
                        top: 5px;
                        right: 10px;
                    }
                }
            }

            .p-dropdown-items-wrapper{
                max-height: 150px !important;

                &::-webkit-scrollbar {
                    width: 10px;
                    position: absolute;
                    right: 0;
                    top: 0;
            
                    @media (max-width: 420px){
                        width: 5px; 
                    }
                }
            
                &::-webkit-scrollbar-track {
                    background: $scrollbar-color;
                }
                
                &::-webkit-scrollbar-thumb {
                    background: $scrollbar-thumb-color;
                    border-radius: 0;
                }
                
                &::-webkit-scrollbar-thumb:hover {
                    background: $scrollbar-thumb-color; 
                }

                .p-dropdown-item{
                    color: $color-dark1;
                    padding: 7px 10px;
                    font-size: 16px;
                    line-height: 101.8%;
    
                    &.p-highlight{
                        color: $color-gray3;
                    }
    
                    &:hover{
                        background: $color-gray2;
                        color: $color-dark1;
                    }
                }

                .p-dropdown-empty-message{
                    color: $color-dark1;
                    padding: 7px 3px;
                    font-size: 16px;
                    line-height: 101.8%;
                }
            }
        }

        &-dark{
            height: 45px !important;

            @media (max-width: $small-screen){
                height: 40px !important;
            }

            .p-dropdown-filter-container{
                .p-dropdown-filter{
                    color: $color-gray3;
                    background-color: $color-dark1;
                }
            }

            .p-dropdown-label{
                color: $color-gray1;
            }
    
            .p-dropdown-panel{
                background-color: $color-dark1;
    
                .p-dropdown-header{
                    .p-dropdown-filter-container{
                        .p-dropdown-filter{
                            color: $color-gray3;
                            background-color: $color-dark1;
                        }
                    }
                }
    
                .p-dropdown-items-wrapper{
                    &::-webkit-scrollbar-track {
                        background: $scrollbar-color;
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        background: $scrollbar-thumb-color; 
                    }
                    
                    &::-webkit-scrollbar-thumb:hover {
                        background: $scrollbar-thumb-color; 
                    }
    
                    .p-dropdown-item{
                        color: $color-gray1;
                       
                        &.p-highlight{
                            color: $color-gray3;
                        }
        
                        &:hover{
                            background: $color-dark4;
                            color: $color-gray3;
                        }
                    }
    
                    .p-dropdown-empty-message{
                        color: $color-gray1;
                    }
                }
            }
        }

        &-gray2{
            .p-dropdown-label{
                color: $color-dark1;
            }
    
            .p-dropdown-panel{
                background-color: $color-gray4;
    
                .p-dropdown-header{
                    .p-dropdown-filter-container{
    
                        .p-dropdown-filter{
                            background-color: $color-gray3;
                        }
    
                        .p-dropdown-filter-icon{
                            color: $color-dark1;
                        }
                    }
                }
    
                .p-dropdown-items-wrapper{
                    &::-webkit-scrollbar-track {
                        background: $scrollbar-color;
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        background: $scrollbar-thumb-color; 
                    }
                    
                    &::-webkit-scrollbar-thumb:hover {
                        background: $scrollbar-thumb-color; 
                    }
    
                    .p-dropdown-item{
                        color: $color-dark1;
                        background-color: $color-gray4;
        
                        &.p-highlight{
                            color: $color-dark1;
                            background: $color-gray6;
                        }
        
                        &:hover{
                            background: $color-gray3;
                            color: $color-dark1;
                        }
                    }
    
                    .p-dropdown-empty-message{
                        color: $color-dark1;
                    }
                }
            }
        }
    }

    &.bg-gray2{
        background-color: $color-gray4;
        border-color: $color-gray4;
        color: $color-dark1;

        .p-dropdown-trigger,
        .p-dropdown-label{
            color: $color-dark1;
        }

        .p-dropdown-panel{
            background-color: $color-gray4;
            border-color: $color-gray4;

            .p-dropdown-item{
                .item-dropdown{
                    color: $color-dark1;

                }

                &:hover {
                    background: $color-gray3;
                    color: $color-dark1;
                }
                &.p-highlight{
                    background: $color-gray6;
                    color: $color-dark1;
                }
            }
        }
    }

    // CUSTOM SIZE
    // ============================= //
    &.md{
        height: 40px;

        .p-dropdown-label{
            padding: 0 13px;
        }

        .p-dropdown-trigger{
            padding-right: 12.5px;
        }

        .p-dropdown-panel{
            margin-top: -41px;

            .p-dropdown-item{
                .item-dropdown{
                    padding: 13px 12.5px 10px;
                }
            }

            .p-dropdown-header{
                .p-dropdown-filter-container{
                    .p-dropdown-filter{
                        height: 40px;
                    }

                    .p-dropdown-filter-icon{
                        top: 12px
                    }
                }
            }
        }
    }

    &.lg{
        height: 54px;

        .p-dropdown-label{
            padding: 0 10.5px;
        }

        .p-dropdown-trigger{
            padding-right: 10.5px;
        }

        .p-dropdown-panel{
            margin-top: -55px;

            .p-dropdown-item{
                .item-dropdown{
                    padding: 17px 10.5px 15px;
                }
            }
        }
    }

    &.xs{
        height: 30px;

        .p-dropdown-label{
            padding: 0 10.5px;
            font-size: 14px;
        }

        .p-dropdown-trigger{
            padding-right: 10.5px;
            font-size: 10px;
        }

        .p-dropdown-panel{
            margin-top: -21px;

            .p-dropdown-item{
                .item-dropdown{
                    padding: 0 6px;
                    font-size: 14px;
                }
            }

            .p-dropdown-header{
                .p-dropdown-filter-container{
                    margin-top: -9px;

                    .p-dropdown-filter{
                        height: 30px;
                    }
                }
            }
        }
    }

    &.calender{
        height: 45px;

        @media (max-width: $small-screen){
            height: 40px;
        }

        .p-dropdown-label{
            justify-content: center;
            color: $color-gray3;
            font-size: 16px;
            line-height: 101.8%;
            padding: 0 14px;
        }

        .p-dropdown-trigger{
            display: none;
        }

        .p-dropdown-panel{
            background-color: $color-dark1;
            border: 0;

            .p-dropdown-items-wrapper{
                max-height: 90px;

                &::-webkit-scrollbar {
                    width: 10px;
                    position: absolute;
                    right: 0;
                    top: 0;
            
                    @media (max-width: 420px){
                        width: 5px; 
                    }
                }
            
                &::-webkit-scrollbar-track {
                    background: $scrollbar-color;
                }
                
                &::-webkit-scrollbar-thumb {
                    background: $scrollbar-thumb-color; 
                    border-radius: 0;
                }
                
                &::-webkit-scrollbar-thumb:hover {
                    background: $scrollbar-thumb-color; 
                }

                .p-dropdown-item{
                    color: $color-gray1;
                    padding: 12px 3px 10px;
                    text-align: center;
                    font-size: 16px;
                    line-height: 101.8%;
    
                    &.p-highlight{
                        color: $color-gray3;
                    }
    
                    &:hover{
                        background: $color-dark4;
                        color: $color-gray3;
                    }
                }
            }
        }
    }
}

// style dropdown on support
.label-support{
    &.p-dropdown{
        height: 44px;

        &.bg-dark{
            background-color: $color-dark3 !important;
        }

        .p-dropdown-label {
            font-size: 18px;
            color: $color-gray3;

            &[aria-expanded="true"] {
                color: $color-gray1;
            }

            &[aria-expanded="false"] {
                color: $color-gray3;
            }

            @media (max-width: $small-screen){
                font-size: 16px;
            }
        }

        .p-dropdown-panel{
            margin-top: 0px;

            .p-dropdown-item{
                .item-dropdown{
                    font-size: 18px;
                    padding: 10px 10px 7px;
                    
                    @media (max-width: $small-screen){
                        font-size: 16px;
                    }
                }
            }
        }
    }

    width: 220px;

    @media (max-width: $small-screen){
        width: 100%;
    }
}

.p-dropdown-items-wrapper{
    max-height: auto !important;
}

// Editor text style
.text-style{
    height: 44px;
    min-width: 96px;

    &.splash {
        height: 35px;

        .p-dropdown-label{
            padding: 2px 10px 0px;
        }

        .p-dropdown-panel{
            margin-top: -36px;
            border: solid 1px $color-dark3 !important;
        }
    }

    .p-dropdown-label{
        padding: 5px 10px 7px;
    }

    .p-dropdown-trigger {
        display: none;
    }

    .p-inputtext,
    .item-dropdown{
        font-size: 16px;
    }

    .p-dropdown-panel{
        margin-top: -45px;

        .p-dropdown-items-wrapper{
            .p-dropdown-item{
                .item-dropdown{
                    padding: 5px 8.5px 6px;
                }
            } 
        } 
    } 
}

// invoices date style on subscribe page
.p-dropdown {
    &.invoices {
        height: 30px;

        &,
        .p-dropdown-panel {
            background-color: $color-dark3 !important;
            border-color: $color-dark3;
            top: 0;
        }

        .p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-item {
            padding: 7px 3px;
        }

        .p-dropdown-item {
            &:hover {
                background-color: $color-dark2 !important;
            }
        }

        .p-dropdown-items-wrapper {
            &::-webkit-scrollbar {
                width: 10px;
                position: absolute;
                right: 0;
                top: 0;
        
                @media (max-width: 420px){
                    width: 5px; 
                }
            }

            &::-webkit-scrollbar-track {
                background: $scrollbar-color;
            }
            
            &::-webkit-scrollbar-thumb {
                background: $scrollbar-thumb-color; 
                border-radius: 0;
            }
            
            &::-webkit-scrollbar-thumb:hover {
                background: $scrollbar-thumb-color; 
            }
        }
    }
}

// style dropdown of select on billing info
.billing-info {
    height: 50px;
    background: $color-gray2;
    border: none;

    @media (max-width: $small-screen) {
        height: 36px;
    }

    .p-dropdown {
        &-trigger-icon {
            display: none;
        }
        
        &-label {
            font-size: 18px;
            color: $color-dark1;
            padding: 0 8px 0 15px;

            @media (max-width: $small-screen) {
                font-size: 16px;
                padding: 0 8px 0 10px;
            }
        }
    }

    .p-placeholder {
        color: #838383;
    }
}

// style dropdown panel billing info
.panel-billing-info {
    max-width: 231px;
    background: $color-gray2;
    border: 1px solid #373737;

    .p-dropdown {
        &-panel {
            width: calc(100% + 2px);
            margin-left: -1px;
            background: $color-gray2;
            border: 1px solid #373737;
        }

        &-filter {
            width: 100%;
            outline: none;
            border: none;
            background: $color-gray3;
            padding: 5px 10px 3px 15px;

            &-icon {
                right: 10px;
            }
        }

        &-item {
            padding: 7px 8px 2px 15px;
            font-size: 18px;
            color: $color-dark1 !important; // style loses by line 48

            &:hover {
                background: $color-gray3;
            }

            &.p-highlight {
                background: $color-gray3;
            }
        }

        &-empty-message {
            padding: 7px 8px 2px 15px;
            color: $color-dark1 !important; // style loses by line 48
        }

        &-items-wrapper {
            @include firefox-only {
                scrollbar-color: $color-dark5 $color-gray1; // scrollbar on Firefox
                scrollbar-width: thin; // scrollbar on Firefox
            }

            &::-webkit-scrollbar {
                width: 10px;
                position: absolute;
                right: 0;
                top: 0;
        
                @media (max-width: 420px){
                    width: 5px; 
                }
            }

            &::-webkit-scrollbar-track {
                background: $scrollbar-color;
            }
            
            &::-webkit-scrollbar-thumb {
                background: $scrollbar-thumb-color;
                border-radius: 0;
            }
            
            &::-webkit-scrollbar-thumb:hover {
                background: $scrollbar-thumb-color; 
            }
        }
    }
}

// promo code style
.coupon-type {
    height: 50px;
    background-color: $color-gray4;
    border: none;

    &.p-disabled  {
        background-color: #646465;

        .p-dropdown-label {
            color: #4d4d4d;
        }
    }

    @media (max-width: $small-screen) {
        height: 40px;
    }

    .p-dropdown-filter {
        outline: none;
        padding: 7px 15px 4px;
        font-size: 18px;
    }

    .p-dropdown-empty-message {
        padding: 12px 15px 10px;
        font-size: 18px;
        color: $color-black;
        background-color: $color-gray4;
        height: auto !important;
    }

    .p-dropdown-label {
        font-size: 18px;
        color: $color-black;
        padding: 0 15px;

        @media (max-width: $small-screen) {
            font-size: 16px;
            padding: 0 10px;
        }
    }

    .p-placeholder {
        color: $color-gray3;
        font-size: 18px;

        @media (max-width: $small-screen) {
            font-size: 16px;
        }
    }
    
    .p-dropdown-item {
        padding: 12px 15px 11px;
        background-color: $color-gray4;
        
        &s {
            margin-bottom: 0;
        }

        @media (max-width: $small-screen) {
            padding: 12px 10px 11px;
        }
        
        span {
            font-size: 18px;
            color: $color-black;

            @media (max-width: $small-screen) {
                font-size: 16px;
            }
        }

        &:hover {
            background-color: $color-gray2;
        }
    }

    .p-dropdown-panel {
        border: none;
        box-shadow: $color-black 0px 1px 5px 0px;
        width: 100%;
        margin-left: 0;

        .p-dropdown-items-wrapper {
            max-height: 201px !important;
        }
    }

    .p-dropdown-trigger-icon {
        display: none;
    }
}

// length value of artwork wizard
.length {
    height: 30px;
    width: 50px;

    .p-dropdown {
        &-trigger {
            display: none;
        }

        &-label,
        &-item {
            font-size: 16px;
            line-height: 20px;
            color: $color-gray3 !important;
            padding: 0;
            justify-content: center;
        }

        &-item {
            padding: 5px 13px 3px;
        }

        &.bg-dark.length {
            background-color: $color-black3 !important;
        }
    }
}

// Account setting timezone
.setting-timezone {
    border: solid 1px $color-dark1;
    background-color: $color-dark1;
    border: 0;
    box-shadow: 0px -4px 4px rgba(41, 41, 41, 0.11);
    height: 45px !important;
    margin-top: -45px;

    .p-dropdown-header{
        .p-dropdown-filter-container{
            .p-dropdown-filter{
                border: 0;
                border-bottom: 1px solid $color-dark3;
                font-size: 16px;
                height: 47px;
                line-height: 101.8%;
                background-image: none !important;
                outline: none;
                padding: 8px 10px 11px;
                color: $color-gray3;
                background-color: $color-dark1;
            }

            .p-dropdown-filter-icon{
                margin-top: 0;
                position: absolute;
                top: 12px;
                right: 10px;
            }
        }
    }

    .p-dropdown-items-wrapper{
        background-color: $color-dark1;

        .p-dropdown-item{
            color: $color-gray1;
            padding: 7px 10px;
            font-size: 16px;
            line-height: 101.8%;

            .item-dropdown{
                color: $color-gray1;
                padding: 13px 12.5px 10px;
    
                &:hover,
                &.p-highlight{
                    background: $color-dark4;
                    color: $color-gray3;
                }
            }

            &.p-highlight{
                color: $color-gray3;
            }

            &:hover{
                background: $color-dark4;
                color: $color-gray3;
            }
        }

        .p-dropdown-empty-message{
            color: $color-gray1;
            padding: 13px 12.5px 10px;
            font-size: 16px;
            line-height: 101.8%;
        }

        &::-webkit-scrollbar {
            width: 10px;
            position: absolute;
            right: 0;
            top: 0;
    
            @media (max-width: 420px){
                width: 5px; 
            }
        }
    
        &::-webkit-scrollbar-track {
            background: $scrollbar-color;
        }
        
        &::-webkit-scrollbar-thumb {
            background: $scrollbar-thumb-color; 
            border-radius: 0;
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background: $scrollbar-thumb-color; 
        }
    }

    .p-dropdown-label{
        color: $color-gray1;
    }

    @media (max-width: $small-screen){
        height: 40px !important;
    }
}

// Input Date Dropdown
.input-date {
    @extend .setting-timezone;
    height: 30px !important;
    margin-top: 0px;
}
.p-colorpicker{
    .p-inputtext{
        &:focus{
            outline: none;
            border: 0;
            box-shadow: 0;
        }
    }

    .p-colorpicker-panel{
        width: 180px;
        
        .p-colorpicker-content{
            position: relative;
            height: 100%;
            background-color: $color-dark1;
        }

        .p-colorpicker-hue-handle{
            border-color: $color-white;
            width: 19px;
            height: 8px;
            border-width: 1px;
            margin-top: -4px;
            opacity: 1;
        }

        .p-colorpicker-hue{
            left: 160px;
            top: 5px;
            width: 15px;
        }

        .p-colorpicker-color-selector{
            top: 5px;
            left: 5px;
        }

        .p-colorpicker-color{
            top: 5px;
            left: 5px;
        }

        .p-colorpicker-color-handle{
            width: 14px;
            height: 14px;
            margin: -7px 0 0 -7px;
            border-color: $color-white;
            opacity: 1;
        }
    }
}